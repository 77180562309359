<template>
    <div
        class="flex flex-col md:flex-row"
        @click="$behavior.track('Inventory', { action: 'Click', location: 'vehicleCard' });"
    >
        <div
            class="p-4 relative list-none rounded border-2 bg-gray-100 md:w-3/4 md:mr-4"
            :class="{'w-2/3': forModalView }"
        >
            <div class="flex flex-col md:flex-row">
                <div class="md:w-2/5">
                    <div class="flex items-center flex-wrap pb-2">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.vin')" />
                        <samp class="w-52 block px-2 py-0.5 -ml-1 border border-gray-300 rounded uppercase tracking-widest bg-white" v-text="inventoryUnit.vin || '---'" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.type')" />
                        <span v-text="inventoryType" />
                    </div>

                    <div class="flex items-center pb-2" v-if="inventoryUnit.division === 'new'">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.demo')" />
                        <span v-text="demo " />
                    </div>

                    <div class="flex items-center pb-2" v-else-if="inventoryUnit.division === 'used'">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.certified')" />
                        <span v-text="certified" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.mileage')" />
                        <span v-text="odometer" />
                    </div>

                    <div class="flex items-center pb-2" v-show="inventoryUnit.integration_name">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.location')" />
                        <span v-text="inventoryUnit.integration_name" />
                    </div>

                    <div class="flex items-center pb-2" v-show="inventoryFilters.lot.visible">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.lot')" />
                        <span v-text="formatField(inventoryUnit.lot)" />
                    </div>

                    <div class="flex items-center pb-2" v-show="inventoryActualCost && (authUser.isDirector() || authUser.isAdmin())">
                        <activix-label class="min-w-32 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.actualCost')" />
                        <span v-text="inventoryActualCost" />
                    </div>

                </div>

                <div class="md:w-3/5">
                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.make')" />
                        <span v-text="formatField(inventoryUnit.make)" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.fuel')" />
                        <span v-text="formatField(inventoryUnit.fuel)" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.transmission')" />
                        <span v-text="formatField(inventoryUnit.transmission)" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.engine')" />
                        <span v-text="formatField(inventoryUnit.engine)" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.bodyType')" />
                        <span v-text="formatField(inventoryUnit.body_type)" />
                    </div>

                    <div class="flex items-center pb-2">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.drivetrain')" />
                        <span v-text="formatField(inventoryUnit.drivetrain)" />
                    </div>

                    <div class="flex items-start pb-2">
                        <activix-label class="min-w-36 pt-1 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.options')" />
                        <activix-tooltip :content="formatTooltip(inventoryUnit.options)">
                            <span
                                id="options"
                                class=" line-clamp-3"
                                v-text="inventoryUnit.options || '---'"
                            />
                        </activix-tooltip>
                    </div>

                    <div class="flex items-start pb-2">
                        <activix-label
                            class="min-w-36 pt-1 mb-0 text-sm text-gray-800"
                            :label="$t('inventory.vehicle.comments')"
                        />
                        <activix-tooltip :content="formatTooltip(inventoryUnit.comments)">
                            <span
                                id="comments"
                                class=" line-clamp-3"
                                v-text="inventoryUnit.comments || '---'"
                            />
                        </activix-tooltip>
                    </div>

                    <div class="flex items-start pb-2 lg:hidden">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.column.color')" />
                        <span class="flex items-center w-1/3 md:hidden" v-text="inventoryColors" />
                        <activix-tooltip :content="formatTooltip(inventoryColors)">
                            <span
                                class="m-auto md:m-0"
                                v-text="inventoryColors"
                            />
                        </activix-tooltip>
                    </div>
                    <div class="flex items-center pb-2" v-show="inventoryFilters.vdp_url.visible">
                        <activix-label class="min-w-36 mb-0 text-sm text-gray-800" :label="$t('inventory.vehicle.vdpUrl')" />
                        <template v-if="vdpUrl">
                            <activix-tooltip :content="$t('clientCard.clickOpenNewTab')">
                                <activix-button
                                    class="mr-2"
                                    size="small"
                                    type="primary"
                                    @click="openUrl"
                                >
                                    <icon name="regular/earth-1" class="text-xs" />
                                </activix-button>
                            </activix-tooltip>
                            <activix-tooltip :content="$t('general.copyToClipboard')">
                                <activix-button
                                    size="small"
                                    type="primary"
                                    @click="copyUrl"
                                >
                                    <icon name="regular/common-file-double-1" class="text-xs" />
                                </activix-button>
                            </activix-tooltip>
                        </template>
                        <span v-text="'---'" v-else />
                    </div>

                </div>
            </div>
        </div>
        <concurrent-opportunities
            :concurrent-opportunities="inventoryUnit.concurrent_opportunities"
            :for-modal-inventory-unit="forModalView"
        />
    </div>
</template>

<script>
    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import ConcurrentOpportunities from '@/components/inventory/ConcurrentOpportunities.vue';
    import { ActivixButton } from '@autosync/atx-activix-ui';

    // Pinia
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import { toCurrency } from '@/utils/numbers.js';

    export default {
        components: {
            ConcurrentOpportunities,
            ActivixLabel,
            ActivixButton,
        },
        props: {
            inventoryUnit: {
                type: Object,
                required: true,
            },
            inventoryFilters: {
                type: Object,
                default: () => {
                },
            },
            category: {
                type: String,
                required: true,
            },
            forModalView: {
                type: Boolean,
                default: false,
            },
            selectedVehicleId: {
                type: Number,
                required: false,
            },
            openedByDefault: {
                type: Boolean,
                default: false,
            },
            showExpandLabel: {
                type: Boolean,
                default: true,
            },
            inventoryColors: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            demo() {
                if (this.inventoryUnit.division === null) {
                    return '---';
                }

                return this.inventoryUnit.demo ? this.$t('general.yes') : this.$t('general.no');
            },

            certified() {
                if (this.inventoryUnit.division === null) {
                    return '---';
                }

                return this.inventoryUnit.certified ? this.$t('general.yes') : this.$t('general.no');
            },

            inventoryType() {
                return this.inventoryUnit.division ? this.$t(`inventory.vehicle.division.${this.inventoryUnit.division}`) : '---';
            },

            inventoryActualCost() {
                return this.inventoryUnit.actual_cost ? toCurrency(this.inventoryUnit.actual_cost) : '---';
            },

            odometer() {
                const unit = this.inventoryUnit.odometer_unit || 'km';

                if (!this.inventoryUnit.mileage) {
                    return `--- ${unit}`;
                }

                return this.$n(this.inventoryUnit.mileage, unit);
            },
            vdpUrl() {
                if (this.$i18n.locale === 'fr') {
                    return this.inventoryUnit.vdp_url_fr ?? this.inventoryUnit.vdp_url_en;
                }
                return this.inventoryUnit.vdp_url_en ?? this.inventoryUnit.vdp_url_fr;
            },
        },

        methods: {
            formatField(field) {
                if (field !== 'none') {
                    return field;
                }

                return '---';
            },

            formatTooltip(content) {
                if (content === '---') {
                    return '';
                }

                return content;
            },

            selectVehicle(vehicleId) {
                if (this.selectedVehicleId !== vehicleId) {
                    this.$emit('selectVehicle', vehicleId);
                } else {
                    this.$emit('selectVehicle', null);
                }
            },

            toggleExpanded() {
                this.expanded = !this.expanded;
            },

            copyUrl() {
                navigator.clipboard.writeText(this.vdpUrl);
                this.$notify.info(this.$t('toastr.urlCopied'));
            },

            openUrl() {
                window.open(this.vdpUrl, '_blank');
            },
        },
    };
</script>
