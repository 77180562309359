<template>
    <div
        class="flex py-2 border-solid border-b-2 border-gray-300 last:border-b-0 justify-between"
        @click="$behavior.track('Inventory', { action: 'Click', location: 'concurrentOpportunities' });"
    >
        <div>
            <div class="flex flex-col">
                <router-link
                    :to="{ name: 'leads.update', params: { id: concurrentOpportunity.id }}"
                    target="_blank"
                    class="font-bold text-lg"
                    @click.native="$behavior.track('Inventory', { action: 'Click', location: 'viewConcurrentOpportuniy' })"
                    v-if="concurentOpportunityIsFromSameAccount"
                >
                    {{ concurrentOpportunity.account_id === contextAccount.id ? leadFullName : concurrentOpportunity.dealer_name }}
                </router-link>
                <activix-tooltip :content="$t('inventory.inventoryFromSyncAccount')" v-else>
                    <span class="font-bold text-lg" v-text="concurrentOpportunity.account_id === contextAccount.id ? leadFullName : concurrentOpportunity.dealer_name" />
                </activix-tooltip>
                <activix-tooltip :content="concurrentOpportunityDateTooltip">
                    <span class="text-gray-600" v-text="concurrentOpportunityCreationDate" />
                </activix-tooltip>
            </div>
        </div>
        <div class="w-1/5 mr-2" v-if="!concurentOpportunityIsFromSameAccount" />
        <div class="flex justify-end items-center">
            <div class="mr-4 flex items-center">
                <activix-tooltip :content="concurrentOpportunityStatus">
                    <icon name="regular/sign-badge-circle" class="mr-2 text-xs" :class="statusIconColor" />
                </activix-tooltip>
                <activix-tooltip :content="concurrentOpportunityLatestSaleStateTooltip">
                    <span class="text-lg truncate" v-text="concurrentOpportunityLatestSaleState" />
                </activix-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead.js';
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        props: {
            concurrentOpportunity: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, { contextAccount: 'account' }),

            leadFullName() {
                return `${new Lead(this.concurrentOpportunity).shortName}`;
            },

            concurrentOpportunityCreationDate() {
                const diffInDays = new ActivixDate('now').diffInDays(new ActivixDate(this.concurrentOpportunity.created_at));
                if (diffInDays === 0) {
                    return `${this.$t('inventory.createdToday')}`;
                }

                return `${this.$t('inventory.concurrentOpportunityDate', [diffInDays])}`;
            },

            concurrentOpportunityDateTooltip() {
                return new ActivixDate(this.concurrentOpportunity.created_at).toHumanShort();
            },

            concurrentOpportunityLatestSaleState() {
                if (!this.concurrentOpportunity?.latest_sale_state) {
                    return this.$t('inventory.latestSaleState.new');
                }

                return this.$t(`inventory.latestSaleState.${this.concurrentOpportunity?.latest_sale_state}`);
            },

            concurrentOpportunityLatestSaleStateTooltip() {
                if (!this.concurrentOpportunity?.latest_sale_state_date) {
                    return null;
                }

                return `${this.concurrentOpportunityLatestSaleState}: ${new ActivixDate(this.concurrentOpportunity.latest_sale_state_date).toHumanShort()}`;
            },

            concurrentOpportunityStatus() {
                if (!this.concurrentOpportunity?.status) {
                    return this.$t('inventory.status.active');
                }
                return this.$t(`inventory.status.${this.concurrentOpportunity?.status}`);
            },

            concurentOpportunityIsFromSameAccount() {
                return this.contextAccount.id === this.concurrentOpportunity.account_id;
            },

            statusIconColor() {
                if (!this.concurrentOpportunity?.status) {
                    return 'fill-green-500 text-transparent';
                }

                if (this.concurrentOpportunity?.status === 'invalid') {
                    return 'fill-orange-500 text-transparent';
                }

                if (this.concurrentOpportunity?.status === 'duplicate') {
                    return 'fill-gray-500 text-transparent';
                }

                if (this.concurrentOpportunity?.status === 'lost') {
                    return 'fill-red-500 text-transparent';
                }
                return '';
            },
        },
    };
</script>
