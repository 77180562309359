<template>
    <label class="m-0 tracking-wide text-sm text-gray-800 uppercase font-semibold" v-text="label" />
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: '',
            },
        },
    };
</script>
