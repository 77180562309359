import accounts from './accounts.js';
import accountNotFound from './accountNotFound.js';
import activityReport from './activity-report.js';
import activixUi from './activix-ui.js';
import addLead from './addLead.js';
import auth from './auth.js';
import automations from './automations.js';
import autopropulsion from './autopropulsion.js';
import calendar from './calendar.js';
import campaign from './campaign.js';
import campaignObjective from './campaignObjective.js';
import campaignStatus from './campaignStatus.js';
import civilities from './civilities.js';
import clientCard from './clientCard.js';
import communicationKinds from './communicationKinds.js';
import communicationMethods from './communicationMethods.js';
import communicationPreferences from './communicationPreferences.js';
import communicationStatuses from './communicationStatuses.js';
import communicationTypes from './communicationTypes.js';
import communications from './communications.js';
import conferenceStatuses from './conferenceStatuses.js';
import consent from './consent.js';
import createdMethods from './createdMethods.js';
import creditScore from './creditScore.js';
import creditScoreChoice from './creditScoreChoice.js';
import dashboards from './dashboards.js';
import dashboardViews from './dashboardViews.js';
import delayDirections from './delayDirections.js';
import delayUnits from './delayUnits.js';
import divisions from './divisions.js';
import elementUi from './elementUi.js';
import emailValidation from './emailValidation.js';
import featurePreview from './featurePreview.js';
import feedback from './feedback.js';
import flows from './flows.js';
import graph from './graph.js';
import groups from './groups.js';
import homepage from './homepage.js';
import history from './history.js';
import imports from './imports.js';
import inturns from './inturns.js';
import inventory from './inventory.js';
import inventoryStockStatus from './inventoryStockStatus.js';
import leadForms from './leadForms.js';
import leadResults from './leadResults.js';
import leadStatus from './leadStatus.js';
import leadTypes from './leadTypes.js';
import leadVehicleCategories from './leadVehicleCategories.js';
import leadVehicleConditions from './leadVehicleConditions.js';
import leadVehicleFrequencies from './leadVehicleFrequencies.js';
import leadVehicleFuels from './leadVehicleFuels.js';
import leadVehicleIntentions from './leadVehicleIntentions.js';
import leadVehicleModalities from './leadVehicleModalities.js';
import leadVehicleStates from './leadVehicleStates.js';
import leadVehicleRvCategories from './leadVehicleRvCategories.js';
import leadVehicleStockStates from './leadVehicleStockStates.js';
import leadVehicleTireTypes from './leadVehicleTireTypes.js';
import leadVehicleTradeTypes from './leadVehicleTradeTypes.js';
import leadVehicleTransmissions from './leadVehicleTransmissions.js';
import lexicon from './lexicon/default.js';
import locales from './locales.js';
import lostSources from './lostSources.js';
import meta from './meta.js';
import navs from './navs.js';
import notFound from './notFound.js';
import notificationCenter from './notificationCenter.js';
import objectives from './objectives.js';
import overview from './overview.js';
import progressStates from './progressStates.js';
import automationSchedule from './automationSchedule.js';
import recurrenceTypes from './recurrenceTypes.js';
import refinancedTypes from './refinancedTypes.js';
import quicktrade from './quicktrade.js';
import schedule from './schedule.js';
import screenPop from './screenPop.js';
import statusPage from './statusPage.js';
import search from './search.js';
import segments from './segments.js';
import sex from './sex.js';
import suppliers from './suppliers.js';
import taskEvents from './taskEvents.js';
import taskEventLocations from './taskEventLocations.js';
import taskEventTypes from './taskEventTypes.js';
import taskEventStatuses from './taskEventStatuses.js';
import taskEventTimelineGroups from './taskEventTimelineGroups.js';
import templates from './templates.js';
import titles from './titles.js';
import unauthorized from './unauthorized.js';
import unsubscribe from './unsubscribe.js';
import users from './users.js';
import vauto from './vauto.js';
import weekdays from './weekdays.js';

export default {
    accounts,
    accountNotFound,
    activityReport,
    activixUi,
    addLead,
    auth,
    automations,
    autopropulsion,
    calendar,
    campaign,
    campaignObjective,
    campaignStatus,
    civilities,
    clientCard,
    communicationKinds,
    communicationMethods,
    communicationPreferences,
    communicationStatuses,
    communicationTypes,
    communications,
    conferenceStatuses,
    consent,
    createdMethods,
    creditScore,
    creditScoreChoice,
    dashboards,
    dashboardViews,
    delayDirections,
    delayUnits,
    divisions,
    emailValidation,
    featurePreview,
    feedback,
    flows,
    graph,
    groups,
    homepage,
    history,
    imports,
    inturns,
    inventory,
    inventoryStockStatus,
    leadForms,
    leadResults,
    leadStatus,
    leadTypes,
    leadVehicleCategories,
    leadVehicleConditions,
    leadVehicleFrequencies,
    leadVehicleFuels,
    leadVehicleIntentions,
    leadVehicleModalities,
    leadVehicleStates,
    leadVehicleRvCategories,
    leadVehicleStockStates,
    leadVehicleTireTypes,
    leadVehicleTradeTypes,
    leadVehicleTransmissions,
    lexicon,
    locales,
    lostSources,
    meta,
    navs,
    notFound,
    notificationCenter,
    objectives,
    overview,
    progressStates,
    quicktrade,
    recurrenceTypes,
    automationSchedule,
    refinancedTypes,
    schedule,
    screenPop,
    statusPage,
    search,
    segments,
    sex,
    suppliers,
    taskEvents,
    taskEventLocations,
    taskEventTypes,
    taskEventStatuses,
    taskEventTimelineGroups,
    templates,
    titles,
    unauthorized,
    unsubscribe,
    users,
    vauto,
    weekdays,
    ...elementUi,
    ...{
        cdkDrive: {
            success: 'Vehicle exported to CDK Drive',
        },
        trash: {
            limitAlert: 'Only the latest {limit} deleted leads are shown.',
            showAll: 'Show all deleted leads',
            showAvailableOnly: 'Show restorable leads only',
        },
        announcements: {
            dismiss: 'Dismiss',
            likeVersion: 'Do you like this version?',
        },
        audio: {
            noAudio: 'No audio',
        },
        tradeReport: {
            general: 'general',
            totals: 'Totals',
            totalsAndAverages: 'Totals / Averages',
            divisionRation: 'division ratio',
            vehicles: 'vehicles',
            evaluatedGeneral: 'evaluated',
            evaluatedValue: 'evaluated',
            boughtGeneral: 'acquired',
            boughtValue: 'acquired',
            lostGeneral: 'lost',
            lostValue: 'lost',
            new: 'new',
            used: 'used',
            detailed: 'resale',
            detailsGeneral: 'resale',
            detailsValue: 'resale',
            wholesaleGeneral: 'wholesale',
            wholesaleValue: 'wholesale',
            returnGeneral: 'returns',
            returnValue: 'returns',
            recycledGeneral: 'recycled',
            recycledValue: 'recycled',
            excludeGeneral: 'excluded',
            excludeValue: 'excluded',
        },
        banner: {
            impersonation: {
                tooltip: 'You are currently impersonating {0}. All your actions are logged under your own account history.',
                title: 'You are impersonating {0}',
                exit: 'End session',
                endingSession: 'Ending session...',
            },
            nylasTransfer: {
                tooltip: 'Please ensure that your email client is updated before January 20 2025 to avoid any disruption in service.',
                title: 'Your email client needs to be updated. ',
                clickHere: 'Click here',
            },
        },
        call: {
            generate: 'Generate a call',
            toNumber: 'To which number would you like to generate the call?',
            toNumber2: 'The call will be generated to the following number:',
            fromNumber: 'From which number would you like to call?',
            fromNumber2: 'The call will be generated from the following number:',
            callInProgress: 'Call in progress',
            cantMakeCall: 'You do not have access to call this lead.',
            callWith: 'Call with',
            home: 'Home',
            cell: 'Mobile',
            work: 'Work',
            contactInfo: 'Lead Contact Info',
            notPreferredPhone: 'Preferred number not selected',
        },
        sms: {
            title: 'SMS Conversation',
            notAuthorized: 'You do not have access to send SMS to this lead.',
        },
        client: {
            addLead: 'Add a lead',
            answered: 'Answered client',
            associatedLeads: '{0} associated lead | {0} associated leads',
            call: 'Call',
            deleteLeads: {
                title: 'Lead deletion | Lead deletion',
                confirmation:
                    'Are you sure you want to delete this lead? | Are you sure you want to delete {count} leads?',
                failed:
                    'A lead could not be deleted because an error occured. Please try again later. | Some leads could not be deleted because an error occured. Please try again later.',
                failedCalling:
                    'A lead could not be deleted because a call is in progress. Please try again later. | Some leads could not be deleted because a call is in progress. Please try again later.',
                failedSingle: 'The lead could not be deleted because an error occured. Please try again later.',
            },
            emailAnswered: 'Email answered',
            incoming_email: 'Incoming email',
            emailNotDelivered: 'Email not delivered',
            leadDeletedSuccessfully: 'The lead was successfully deleted.',
            leadxpressEmail: 'Web Lead Email',
            leadTrashFetchFailed: 'Cannot fetch trashed lead invalid account',
            makeCall: 'Call',
            pendingReassign: 'Assignment request pending.<br>Does not count towards your stats.',
            waiting: 'Awaiting reply',
            noEmail: 'No valid email address',
            noMobilePhone: 'No mobile phone available',
            noPhone: 'No phone available',
            outgoing_email: 'Outgoing email',
            outgoing_email_video: 'Outgoing video email',
            previousEmails: 'Previous email exchange',
            reply: 'Reply',
            restore: {
                needDeletePermission: 'To restore this lead you need to have access to the restoration/deletion option',
                success: 'Lead {0} restored',
                cannotRestoreSupplierLead: 'Cannot restore leads linked to {0}',
                restoreFailed: 'Restore failed please retry',
            },
            sendEmail: 'Email',
            type: 'Type',
            withEmail: 'With email',
            withPhone: 'With phone',
            withoutEmail: 'Without email',
            withoutPhone: 'Without phone',
            withoutEmailAndPhone: 'Without email and phone',
            withoutEmailAndMobile: 'Without email and  mobile',
            withMobile: 'With mobile',
            withoutMobile: 'Without mobile',
            wrongEmail: 'The email is invalid',
            wrongPhone: 'The number is invalid',
        },
        commercial: {
            antiThief: 'Anti theft',
            approved: 'Approved | Approved',
            averageProfit: 'Average profit',
            average: 'Average',
            cash: 'Cash down',
            cashDeal: 'Cash deal',
            clientExclude: 'Client excluded | Clients excluded',
            clientMet: 'Client met | Clients met',
            clientNotMet: 'Client not met | Clients not met',
            clientRefused: 'Client refused | Clients refused',
            delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural',
            endorser: 'Co-signer',
            financing: 'Financing',
            inspected: 'Inspected',
            institution: 'Institution',
            insurance: 'Insurance',
            meeting: 'Met',
            noNeed: 'Excluded',
            other: 'Other',
            pending: 'Pending request | Pending requests',
            premium: 'Premium',
            prepared: 'Prepared',
            profit: 'Profit',
            progressState: 'State',
            proof: 'Proof',
            vehicleSelection: 'Vehicle to select',
            refused: 'Refused',
            replacement: 'Replacement',
            sent: 'Sent',
            totalPrice: 'Total services @:lexicon.sold.singular',
            totalPriceCommercialDirector: 'Total services @:lexicon.sold.singular (F&I)',
            totalProfit: 'Total profit',
            warranty: 'Warranty',
        },
        date: {
            appointment: 'Appointment',
            appointmentDate: 'Appointment date',
            apptCallDate: 'Phone appt. date',
            apptConfirmationDate: 'Appt. confirmation date',
            available: 'Availability',
            availableDate: 'Available date',
            beBack: 'B-back',
            beBackDate: 'Be back date',
            billOfSaleCreatedAtDate: 'Bill of sale creation date',
            billOfSaleSentToCustomerAtDate: 'Bill of sale sent to customer date',
            billOfSaleSignedByCustomerAtDate: 'Bill of sale signed by customer date',
            callDate: 'Call date',
            communicationDate: 'Communication date',
            lostDateTooltip: 'Click here to filter the leads lost in the date range',
            createdAt: 'Creation date',
            createdDate: 'Creation date',
            creatingPrebookingLeadDate: 'Appointment date',
            csi: 'CSI',
            csiDate: 'CSI date',
            customerDepositReceivedAtDate: 'Customer deposit received date',
            customerReceiptSentAtDate: 'Customer receipt sent date',
            dateSelected: 'date selected | dates selected',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            deliverableDate: '@.capitalize:lexicon.deliverable.singular date',
            delivered: '@.capitalize:lexicon.delivered.singular',
            deliveredDate: '@.capitalize:lexicon.delivered.singular date',
            deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
            discountedDate: 'Funded',
            discountedFilterDate: 'Funded date',
            endContractDate: 'End contract date',
            endWarrantyDate: 'End Warranty date',
            invalidDate: 'Invalid date',
            lastPresentedDate: 'Last visit date',
            lastCommunicationDate: 'Last communication date',
            modificationDate: 'Modification Date',
            nextPresentedDate: 'Next revival',
            paperworkDate: 'Paperwork date',
            presented: 'Showed',
            presentedDate: 'Visit date',
            recordedDate: 'Report date',
            refinanced: 'Buy Out.',
            refinancedDate: 'Buy Out date',
            renewal: '@.capitalize:lexicon.portfolio.singular',
            renewalDate: '@.capitalize:lexicon.renewal.singular date',
            roadTest: 'Test drive',
            roadTestDate: 'Test drive date',
            sale: '@.capitalize:lexicon.sale.singular',
            saleByPhone: 'Sold by phone',
            saleByPhoneDate: '@.capitalize:lexicon.sale.singular by phone date',
            saleDate: '@.capitalize:lexicon.sale.singular date',
            sentToCustomerAtDate: 'Sent to customer date',
            statusUpdatedAt: 'Status update date',
            takeOver: 'Turn over',
            takeOverDate: 'Turn over date',
            taskDate: 'Task date',
            updatedAt: 'Last update date',
            repairDate: 'Repair date',
            openWorkOrderDate: 'W.O. opening',
            virtualAppointmentDate: 'Virtual appointment date',
            workOrderPartialClosureDate: 'W.O. partial closure',
            workOrderClosureDate: 'W.O. closure',
        },
        datepicker: {
            end: 'End date',
            fiscalYearToDate: 'Year to date',
            last30Days: 'Last 30 days',
            last3Month: 'Last 3 months',
            last6Month: 'Last 6 months',
            last12Month: 'Last 12 months',
            last7Days: 'Last 7 days',
            lastWeek: 'Last week',
            last90Days: 'Last 90 days',
            lastMonth: 'Last month',
            previousYear: 'Last year',
            thisYear: 'This year',
            sinceBeginning: 'From the beginning',
            start: 'Start date',
            thisMonth: 'Current month',
            thisWeek: 'Current week',
            tomorrow: 'Tomorrow',
            today: 'Today',
            yesterday: 'Yesterday',
            monthsAgo2: '2 months ago',
            monthsAgo3: '3 months ago',
            nextMonth: 'Next month',
            next3Month: 'Next 3 months',
            next6Month: 'Next 6 months',
            next12Month: 'Next 12 months',
            next18Month: 'Next 18 months',
            next24Month: 'Next 24 months',
            next36Month: 'Next 36 months',
            olderThan: 'Older than {months} months',
            newerThan: 'Newer than {months} months',
            next7: 'Next 7 days',
            next30: 'Next 30 days',
            next90: 'Next 90 days',
            custom: 'Custom date',
        },
        deliveryTimeframe: {
            fifteen_minute: '15m',
            twenty_minute: '20m',
            twenty_five_minute: '25m',
            thirty_minute: '30m',
            thirty_five_minute: '35m',
            forty_minute: '40m',
            forty_five_minute: '45m',
            fifty_minute: '50m',
            fifty_five_minute: '55m',
            one_hour: '1h',
            one_hour_fifteen_minute: '1h15m',
            one_hour_thirty_minute: '1h30m',
            one_hour_forty_five_minute: '1h45m',
            two_hour: '2h',
            two_hour_fifteen_minute: '2h15m',
            two_hour_thirty_minute: '2h30m',
            two_hour_forty_five_minute: '2h45m',
            three_hour: '3h',
            three_hour_fifteen_minute: '3h15m',
            three_hour_thirty_minute: '3h30m',
            three_hour_forty_five_minute: '3h45m',
            four_hour: '4h',
        },
        error: {
            ajaxq: 'Some data is still being saved and might be lost. @:general.wishToProceed',
            generic: 'An error has occured.',
            leadIdEmpty: 'The lead has an invalid format, please contact technical support.',
            leadNotFound: 'The lead could not be found. It has probably been deleted.',
            needUpdateInLead: 'To go back to the Who\'s Next queue, you must create or edit a lead or ask your manager.',
            selectAccountWarning: 'You must select an account with the dropdown above.',
            unauthorized: 'Unauthorized access',
            warning: 'Warning',
            communicationNotFound: 'The communication was not found.',
            noLeadXpressWarning: 'The Web Lead priority is deactivated, the priorities will not be applied.',
            noSaleTable: "The selected account doesn't have the @:lexicon.sale.singular table option",
            noName: 'Name is required',
            noInTurn: "The selected account doesn't have the Who's Next option",
        },
        followUp: {
            saleIsByPhone:
                "The selected lead doesn't have a presented date. Do you want to make a @:lexicon.sale.singular by phone instead ?",
            goToOriginalLead: 'Show the original lead',
            emailCount: 'Email communication',
            last: 'Last communication',
            lastDate: 'Last communication date',
            none: 'No communication',
            noEmail: 'No email',
            noCall: 'No call',
            noSms: 'No SMS',
            noAutomated: 'No Automated',
            noMessenger: 'No Messenger',
            noVideo: 'No video',
            phoneCount: 'Phone communication',
            smsCount: 'SMS communication',
            messengerCount: 'Messenger communication',
            invalidDate: 'Please select a valid date',
            invalidDateBefore: 'Please select a date on or before today',
            videoCount: 'Videoconference communication',
            withAutomated: 'Automated',
            withCall: 'Call',
            withEmail: 'Email',
            withMessenger: 'Messenger',
            withSms: 'SMS',
            withVideo: 'Video',
            addSaleByPhone: '@.capitalize:lexicon.sale.singular by phone',
            vehicleIsSoldInDifferentLeadLink: 'Lead',
            vehicleIsSoldInDifferentLead: 'This wanted vehicle is already marked as sold in another ',

            todo: {
                appointment: 'Appointment',
                automated: 'Automated',
                call: 'Call',
                csi: 'CSI',
                delivery: '@.capitalize:lexicon.delivery.singular',
                email: 'Email',
                late: 'Not completed',
                none: 'Ø task',
                other: 'Other',
                phoneAppointment: 'Phone appt.',
                sms: 'SMS',
                virtualAppointment: 'Virtual appointment',
                testDrive: 'Test drive',
            },
        },
        optOut: {
            email: 'Email',
            sms: 'SMS',
            call: 'Tel',
            dnd: 'DND.',
            since: 'Since the {0}',
            until: 'DND. until {0}',
            indefinitely: 'DND. indefinitely',
        },
        general: {
            account: 'Account',
            accountColumn: '@.capitalize:lexicon.account.singular',
            activated_at: 'Activation date',
            active: 'Active',
            activeAccount: 'Active accounts',
            add: 'Add',
            advisor: 'Associate',
            advisorAgent: 'Associate / Agent',
            agent: 'BDC Agent',
            allTeams: 'All teams',
            appointment: 'Appt.',
            areYouSure: 'Are you sure?',
            assignees: 'Assignees',
            back: 'Previous page',
            backHome: 'Back to home',
            backToSummary: 'Back to users summary',
            bdc: 'BDC',
            by: 'By',
            call: 'Call | Calls',
            cancel: 'Cancel',
            cancelSelection: 'Cancel selection',
            confirmSelection: 'Confirm selection',
            child: 'Child',
            childAccounts: '"Child" accounts',
            city: 'City',
            clickHere: 'Click here',
            client: 'Client | Clients',
            close: 'Close',
            commercial: 'F&I',
            continue: 'Continue',
            copy: 'Copy to clipboard',
            copyToClipboard: 'Copy',
            copiedSuccessfully: 'Copied successfully',
            copiedUnsuccessfully: 'Unable to copy, please select manually',
            create: 'Create',
            createTemplateTooltipDisabled: 'You do not have access to create templates',
            currentAbr: ' - C',
            currentVehicle: 'Current vehicle',
            country: 'Country',
            date: 'Date | Dates',
            dealer: 'Dealer',
            default: 'Default',
            defaultTemplateTooltipDisabled: 'You do not have access to modify the default template',
            delete: 'Delete',
            deleting: 'Deleting...',
            directorTO: 'T.O. Director',
            division: 'Division',
            done: 'Done',
            download: 'Download',
            drsLink: '{0} Digital retailing',
            drsLinkClient: 'Client Link',
            drsLinkDealer: 'Dealer Link',
            duplicateTemplateTooltipDisabled: 'You do not have access to duplicate templates',
            edit: 'Edit',
            editTemplateTooltipDisabled: 'You do not have access to edit templates',
            email: 'Email',
            excludeTerm: 'Prefix ! to exclude',
            excludeAndMultipleTerms: 'Prefix ! to exclude. Separate inputs with , to include multiple terms',
            favorite: 'Favorite',
            featureDisabled: 'Feature unavailable at the moment',
            followUp: 'Communication',
            followBy: 'Communicated by',
            goToClientCard: 'Go to the client card',
            goToLink: 'Go to link',
            groups: 'Groups',
            howToAssociate: "You can also associate the lead, here's how!",
            importFile: 'Import file',
            importantInformation: 'Important information',
            inactive: 'Inactive',
            inactiveAccount: 'Inactive accounts',
            incoming: 'Incoming',
            insideDates: 'Inside dates',
            invalidLink: 'Invalid Link',
            lang: 'Language',
            lead: 'Lead | Leads',
            lessThanAMin: 'Less than 1 min',
            loading: 'Loading...',
            loadingMatching: 'Matching columns in progress...',
            media: 'Media',
            make: 'Make',
            model: 'Model',
            new: 'New',
            next: 'Next',
            no: 'No',
            none: 'None',
            note: 'Note | Notes',
            noteDate: 'Note date',
            offer: 'Offer #',
            ok: 'Ok',
            openLink: 'Open link',
            optOut: 'Opt-out',
            or: 'or',
            outgoing: 'Outgoing',
            outsideDates: 'Outside dates',
            parentAccounts: 'Select the parent account',
            phone: 'Phone',
            phoneAppointment: 'Phone appt.',
            phoneUp: 'Phone-up',
            postalCode: {
                CA: 'Postal code',
                US: 'ZIP code',
            },
            previous: 'Previous',
            price: 'Price',
            province: {
                CA: '@.capitalize:lexicon.province.CA',
                US: 'State',
            },
            regular: 'Regular',
            restore: 'Restore',
            result: 'Result | Results',
            save: 'Save',
            saveAndClose: 'Save & Close',
            saveAndEdit: 'Save & Edit',
            saveAndNew: 'Save & New',
            saveAndDuplicate: 'Save & Duplicate',
            savedSuccessfully: 'Saved successfully',
            search: 'Search',
            secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
            serviceAgentShort: 'Serv. Agent',
            services: 'Services',
            skip: 'Postpone',
            status: 'Status',
            stock: 'Stock #',
            stockState: 'Stock state',
            submit: 'Submit',
            suspended: 'Suspended',
            team: 'Team',
            timezone: 'Timezone',
            to2: 'to',
            to: 'to',
            total: 'Total',
            unknown: 'Unknown',
            update: 'Update',
            user: 'User | Users',
            vehicle: 'Vehicle',
            walkIn: '@.capitalize:lexicon.walkIn.singular',
            wantedAbr: ' - W',
            wantedVehicle: 'Wanted vehicle',
            webBoost: 'WebBoost',
            webOrder: 'Order | Orders',
            wishToProceed: 'Do you wish to proceed?',
            with: 'With',
            without: 'Without',
            withoutSources: 'Without sources',
            year: 'Year',
            yes: 'Yes',

            months: {
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                may: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December',
            },
        },
        option: {
            showNoModality: 'Display leads without end contract dates and that are not cash deals',
            hideNoModality: 'Hide leads without end contract dates and that are not cash deals',
            enableFilterUserByDivision: "Activate to filter based on the associate's division",
            disableFilterUserByDivision: "Deactivate to filter based on the lead's division",
            enableIncludeAttributions: 'Activate to include data from attributed leads',
            disableIncludeAttributions: 'Deactivate to exclude data from attributed leads',
            enableResultStat: 'Activate to filter on creation date or results (checkbox)',
            disableResultStat: 'Deactivate to filter by date types',
            enableEndContractStat: 'Click to see stats depending on end of contract dates and cash deals',
            disableEndContractStat: 'Click to see stats according to creation dates only',
            enableDoubleWalkIn: 'Click to calculate the be-back twice (2) in a same month',
            disableDoubleWalkIn: 'Click to calculate the be-back once (1) in a same month',
            enableSavedDate: 'Click to see stats for vehicles with a recorded date',
            disableSavedDate: 'Click to see stats for @:lexicon.sold.singular vehicles',
            enableShowStats: 'Click to show the stats',
            disableShowStats: 'Click to hide the stats',
            enableCommercialStat:
                'Click to see stats based on customers that have been met minus the refused and excluded ones',
            disableCommercialStat: 'Click to see stats based on @:lexicon.delivered.singular vehicles',
            generateReport: 'Generate report',
            reportBuilder: 'Report builder',
            showAgent: 'Show BDC agents',
            showAdvisor: 'Show associates',
            showBdcLead: 'Group by BDC',
            showAdvisorLead: 'Group by associates',
            showCommercialLead: 'Group by financial directors',
            showTakeOverDirectorLead: 'Group by turn over directors',
            showAccounts: 'Group by account',
            showSources: 'Group by source',
            showForms: 'Group by form',
        },
        result: {
            active: 'Active',
            addAppointment: 'Add an appointment',
            appointment: 'Appointment',
            appointments: 'Appts',
            appointmentByCall: 'Appt. by call',
            appointmentByCallLong: 'Appointment by call',
            appt: 'Appt. | Appts.',
            apptCall: 'Appt. by phone',
            apptConfirmation: 'Confirmation',
            assigned: 'Assigned lead',
            attempted: 'Attempted | Attempted',
            automation: 'Automation',
            beBack: 'B-back',
            calling: 'Calling',
            canceled: 'Canceled | Canceled',
            canceledByClient: 'Canceled by client',
            canceledByDealer: 'Canceled by dealer',
            canceledByUser: 'Canceled by ',
            cancelationReason: 'Cancelation reason',
            canceledNoMoreVehicle: 'No longer owns the vehicle',
            client: 'Client',
            confirmed: 'Confirmed | Confirmed',
            conquest: 'Conquest',
            csi: 'CSI',
            dealer: 'Dealer',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            delivered: '@.capitalize:lexicon.delivered.singular',
            deposit: 'Deposit',
            destination: 'Destination',
            duplicate: 'Duplicate',
            endLcap: 'End LCAP',
            endLnette: 'End LNETTE',
            endWarranty: 'End warranty',
            error: 'Error',
            firstAppointment: 'First appt.',
            happy: 'Positive',
            insideHours: 'During business hours',
            insideSchedule: 'During associate schedule',
            interrupted: 'Interrupted',
            invalid: 'Invalid',
            leadAlreadyHasAppointment: 'This lead already has an appointment',
            leadAlreadyPresented: 'This lead already has a visit',
            leadIsService: 'This is a Service lead',
            leadIsServiceWithConfirmation: 'This lead is from the Service division and has a confirmed appointment',
            leadIsSoldbyPhone: 'This lead is @:lexicon.sold.singular by phone',
            leadIsWalkIn: 'This lead is @:lexicon.walkIn.prefix',
            leadStatusIs: 'The status of this lead is: {0}',
            longTerm: 'Long term',
            lost: 'Lost',
            lostReason: 'Lost reason',
            lostSource: 'Lost source',
            invalidReason: 'Invalid reason',
            loyalty: 'Loyalty',
            missed: 'Missed',
            modifyPhoneAppointment: 'Modify phone appointment',
            neutral: 'Neutral',
            noMoreVehicle: 'No longer owns the vehicle',
            noShow: 'No Show',
            notSold: 'Not @:lexicon.sold.singular',
            nowShow: 'No show',
            origin: 'Origin',
            other: 'Other',
            outsideHours: 'Outside business hours',
            outsideSchedule: 'Outside associate schedule',
            pending: 'Pending',
            pendingWb: 'Pending',
            presented: 'Visit',
            presentedColumn: '@.capitalize:lexicon.dashboard_columns.presented',
            promo: 'Promo',
            reached: 'Reached',
            reminder: 'Reminder',
            renewalByPhone: '@.capitalize:lexicon.renewed.singular by phone | @.capitalize:lexicon.renewed.plural by phone',
            renewed: '@.capitalize:lexicon.renewed.singular',
            revive: 'Revive',
            roadTest: 'Test drive',
            sale: '@.capitalize:lexicon.sold.singular',
            saleByPhone: '@.capitalize:lexicon.sold.singular by phone | @.capitalize:lexicon.sold.singular by phone',
            saleByPhoneIcon: '@.capitalize:lexicon.sold.singular by phone',
            service: 'Service',
            takeOver: 'T.O.',
            takeOverBy: 'T.O. by',
            todo: 'To do',
            twenty_four_hour: '24 hr',
            twentyFourHr: '24 hr',
            unhappy: 'Negative',
            unknown: 'Unknown',
            verified: 'Verified',
            verifiedBy: 'Verified by',
            verifiedSale: 'Verified sale',
            waitingSale: 'Waiting',
            wow: 'Wow',
        },
        viewSelect: {
            myViews: 'My Views',
            system: 'System',
            sharedViews: 'Shared Views',
            viewOwner: 'Owner',
        },
        selectpicker: {
            all: 'All',
            allSelected: 'All selected',
            noneSelected: 'None selected',
            search: 'Search',
            selected: 'select',
            selectAll: 'Select all',
            xSelected: '{0} selected',
            xSelect: '{0} select',
        },
        toastr: {
            back: 'Back',
            connectionTimeout: 'Connexion interrupted',
            networkDisconnected: 'Network disconnected',
            sessionExpired: 'Your session has expired.',
            tokenRefreshed: 'Session retrieved.',
            cantImpersonate: 'Failed to impersonate',
            impersonateSuccess: "You're now impersonating {0}",
            error: 'Error!',
            errorMessage: 'Sorry, an error occured.',
            errorMessages: {
                sendingEmail: 'Failed to send email',
                updatingLead: 'Failed to update lead',
                niotext: 'Sorry, an error occured on the NioText side. Try again.',
                audioNotAllowed: 'Please allow access to your microphone to record an audio',
                audioNotSupported: 'Your browser does not seem to support audio recording',
                filesTooBig: 'Files total size over limit of {maximumSize}',
                invalidData: 'Failed to send email. Data is invalid',
                invalidImage: 'Failed to send email. Invalid file.',
            },
            errorNetwork: 'Connexion interrupted, try again',
            featurePreviewActivationFailed: 'Sorry, an error occured while activating the feature.',
            leadDeleted: 'Lead deleted by',
            leadNotFound: 'No lead found',
            goToLead: 'Go to the client card',
            nioTextPhoneCantBeDeleted: 'The phone number is used in NioText',
            phoneMustBeMobile:
                'The lead phone number is already used in NioText, you can only change it for a valid mobile number',
            noPhoneNioText: 'You have reached your limit of separate SMS conversations with this contact.',
            noMobilePhone: "The lead doesn't have a valid mobile phone number.",
            planYourNextStep: 'Please plan your next step',
            refresh: 'Refresh the page',
            success: 'Success',
            unauthorized: 'Unauthorized access!',
            warning: 'Warning!',
            reconnect: 'Please reconnect.',
            urlCopied: 'URL copied to your clipboard',
            prebookingLeadCreated: 'Pre-booking lead and appointment created',
            exportInProgress: 'Export in progress',
        },
        weekDay: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        delays: {
            before: 'before',
            day: 'day | days',
            hour: 'hour | hours',
            minute: 'minute | minutes',
            infinite: 'Infinite',
            month: 'month | months',
            sameDay: 'Same day',
            today: 'today',
            week: 'week | weeks',
            year: 'year | years',
            toDo: 'To do',
            threeDay: '3 days',
        },
        modal: {
            address: 'Address',
            noAddress: 'Lead has no address',
            allNotes: 'All notes',
            associateLeads: 'You will associate {count} leads together.',
            associateLeadsTitle: 'Lead association',
            yes: 'Yes',
            virtualAppointment: 'Virtual appointment',
            virtualAppointmentAdvisorLink: 'Your link',
            virtualAppointmentCustomerLink: 'Customer link',
            warrantyChangeAlert: 'Do you wish to calculate the warranty end date with those new changes?',
            onlyThisOne: 'No, only this one',
            deleteAutomatedEvent: 'Delete an Automated Event',
            modifyAutomatedEvent: 'Modify an Automated Event',
            modifyAutomatedEventFuture: 'This and following events',
            modifyAutomatedEventCurrent: 'This event',
            modifyAutomatedEventAll: 'All the events',
            modifyAutomatedEventTask: 'Automated event. <br> Click to view automation',
            modifyAllLeadsCampaign: 'You will create a campaign for all {count} leads.',
            modifyAllLeadsEmail: 'You will send an email to all {count} leads.',
            modifyAllLeadsEvent: 'You will add all {count} leads to an event.',
            modifyAllLeadsCampaignTitle: 'Campaign creation',
            modifyAllLeadsEmailTitle: 'Mass mailing',
            modifyAllLeadsEventTitle: 'Add leads to an event',
            firstExecutionDate: 'First execution date',
            nextExecutionDate: 'Next execution date',
            recurrence: 'Recurrence',
            users: 'Users',
            startDate: 'Start date',
            endDate: 'End date',
            update_future_confirm_message:
                'This event has an automated event linked, do you wish to update it too? À This event has {0} automated events linked, do you wish to update those too?',
            disabledTaskType: 'This event is linked to your performances and impact your statistics.',
            add: 'Add',
            cancel: 'Cancel',
            confirm: 'Confirmation',
            account: 'Account',
            accounts: 'Accounts',
            send: 'Send',
            groups: 'Groups',
            childAccounts: 'Active Accounts',
            parentAccounts: 'Parent Accounts',
            icsInvite: 'Calendar Reminder',
            servicePrebooking: 'Prebook a service appointment',
            later: 'Later',
            leadAssignText: '{0} is already assigned to this lead.',
            leadAssignReason: 'What is the reason of your assignment request?',
            leadAssignUser: 'Who would you like to assign?',
            leadAssignRequest: 'Comment:',
            moreInfoToDirection: 'The directors concerned were notified. They will be able to accept or decline the request.',
            confirmed: 'Confirmed',
            canceled: 'Canceled',
            completed: 'Completed',
            sendConfirmation: 'Send a confirmation?',
            doNotSendConfirmation: 'Do not send a confirmation',
            confirmedTooltip: 'The appointment was confirmed by the client',
            canceledTooltip: 'The appointment was canceled by the client',
            confirmationDisabled: 'The lead must have at least one verified phone number to use SMS confirmation',
            niotextNeeded: 'NioText is needed to use this feature',
            icsTooltip: 'Send a calendar reminder by email',
            invalidStartAt: 'The start date is invalid.',
            invalidEndAt: 'The end date is invalid.',
            editEventTitle: 'Edit an appointment',
            editTaskTitle: 'Edit a task',
            editTaskEventTitle: 'Edit a task or appointment',
            add_event2: 'Add an appt.',
            edit_event: 'Edit an appt.',
            add_task: 'Add a task',
            edit_task: 'Edit a task',
            noRecurrence: 'No recurrence',
            othersTooltip: 'Unsupported communication methods',
            deleteFollowUp:
                'Deleting {0} is irreversible. Keep in mind that you can edit the date from the client card.',
            followUpDate: {
                appointment: 'the appointment date',
                appt_call: 'the phone appointment date',
                appt_confirmation: 'the appointment confirmation',
                available: 'the available date',
                be_back: 'the b-back date',
                bill_of_sale_created_at: 'the bill of sale creation date',
                bill_of_sale_sent_to_customer_at: 'the bill of sale sent to customer date',
                bill_of_sale_signed_by_customer_at: 'the bill of sale signed by customer date',
                csi: 'the CSI date',
                customer_deposit_received_at: 'the customer deposit received date',
                customer_receipt_sent_at: 'the customer receipt sent date',
                deliverable: 'date where the vehicle can be @:lexicon.delivered.singular',
                delivered: '@:lexicon.delivered.singular',
                delivery: 'the @:lexicon.deliveryDate.singular',
                discounted: 'this funded',
                paperwork: 'the paperwork date',
                presented: 'the visit date',
                presented_other: 'the other visit date',
                recorded: 'this report date',
                refinanced: 'Buy Out',
                road_test: 'the test drive date',
                home_road_test: 'the home test drive date',
                sale: 'this @:lexicon.sale.singular',
                take_over: 'this turn over',
            },
            disableUserWarning: {
                additionalAction: 'Additional actions',
                transferCommunicationsAndLeadNotSold: 'You should transfer all unsold leads to another user.',
                explanationOfProcedure: 'Explanation of procedures',
            },
            add_task_event: 'Add an appt. or a task',
            modalityCashDeal: 'Modality Cash Deal',
            taskReadOnlyTitle: 'Task details',
            eventReadOnlyTitle: 'Event details',
            taskEventReadOnlyTitle: 'Task or event details',
            guestMode: 'Invitation',
            createdBy: 'Created by',
            chooseDateRage: 'Please choose your start and end date',
            chooseExportType: 'Please choose an export type',
            chooseIncludeSubGroup: 'Include subgrouping',
            chooseIncludeSubGroupToolTip: 'Allows you to generate your PDF report with the subgroup that is active according to the filters of the current view.',
            exportType: 'Export type',
            generate: 'Generate',
            newTab: 'New tab',
            reportLeadsOnly: 'The stats will not be included in the report; it will only contain the leads.',
            reportStarted: 'You will receive an email at {0} with the report in a few minutes.',
            subscriptionCreated: 'You will receive an email {1} at {0} with the {4} report of this view starting on {2} at {3}.',
            type: 'Type',
            desc: 'Description',
            title: 'Title',
            start_at: 'Start',
            end_at: 'End',
            dealershipTime: 'Dealership Time',
            dealershipTimeError: 'Timezone could not be determined. Please verify manually to prevent scheduling discrepancies.',
            guests: 'Guests',
            errors: 'Errors',
            date: 'Date',
            addReminder: 'Add a reminder',
            createAppointmentConfirmMessage: 'Create an appointment based on this phone appointment?',
            createAppointment: 'Create Appointment',
            reminder: 'Reminder',
            call: 'Call',
            email: 'Email',
            oneInformationRequired: 'Please fill in one of the following fields',
            other: 'Other',
            event: 'Appointment',
            phoneAppointment: 'Phone appt.',
            testDrive: 'Test drive',
            delivery: '@.capitalize:lexicon.delivery.singular',
            location: 'Location',
            locations: {
                dealership: 'At dealership',
                customer: 'At customer location',
                custom: 'Custom',
            },
            vehicles: 'Vehicles',
            priority: 'Priority',
            low: 'Low',
            normal: 'Normal',
            high: 'High',
            done: 'Done',
            calendar: 'Calendar',
            reminders: 'Preset reminders',
            minutes_before: 'minutes before',
            hours_before: 'hour before|hours before',
            nineoclock_am: 'At 9AM the same day',
            nineoclock_pm: 'At 9PM the day before',
            sms: 'SMS',
            push: 'Mobile',
            email_alert: 'Email alert',
            sms_alert: 'SMS alert',
            client: 'Client',
            notification: 'Screen',
            user: 'Associate',
            nextStepTitle: 'Please complete the next step',
            nextStep: 'Next step',
            term: 'Term',
            modality: 'Modality',
            termAndModality: 'Term and modality',
            testDriveCompleted: 'Test completed',
            titleAddRenew: 'Add @:lexicon.portfolio.singular lead',
            titleAssignAdvisor: 'Assignment request',
            endContractRenew: 'End contract date',

            // Delete task event
            deleting: 'Deleting',
            delete_confirm_message: 'Are you sure you want to delete this appointment or this task?',
            delete: 'Delete',
            deleted: 'Deleted',
            deleted_message: 'Your appointment was deleted!',
            deleteConfirmMessageMainEvent:
                'Warning! This appointment is linked to your performance and will impact your statistics.',

            // Errors
            error_title: 'The title must not be empty',
            error_start_at: 'The start date must not be empty',
            error_email: 'Calendar reminders can only be sent to a valid email address',
            error_date: 'The date must not be empty',
            error_end_at: 'The end date must not be empty',
            error_end_is_before: 'The start date must be prior to the end date',
            error_is_canceled: 'Calendar reminder not available, event canceled, client no show or no task owner',
            my_calendar: 'My calendar',
            go_lead: 'Go to the lead',
            save: 'Save',
            error_lead: 'The lead id cannot be empty',
            add_event: 'Add a task or an appointment',
            add_comment: 'Add a comment',
            error: {
                reminderDate: "The reminder's date cannot be empty",
            },
            error_no_selected_user: 'You must choose a user to notify',
            error_no_type: 'You must choose a type of task or appointment',
            error_invalid_date: 'You have an invalid date',
            error_main_event_date_is_after: 'The {0} date cannot be later than today',
            noAssociatedEventLead: {
                errorDesc: 'An event lead must be associated with at least one non-event lead.',
                errorTitle: 'Modification disabled',
                actionAssociate: 'Associate',
                actionDismiss: 'Dismiss',
            },
            sendIcsVirtualAppointment: 'You and the client will receive confirmation and appointment change emails.',
            task_owner: 'Owner',
            no_lead: 'No assigned lead',

            call_client: 'Generate a call',
            user_phone_choice: 'From which number would you like to call?',
            call_generated: 'Call generated',
            call_to: 'You will receive a call to this following number',

            send_email: 'Send an email',
            selectTerm: 'Select term',
            agree: 'Accept',
            associate_email: '* By accepting, you will be assigned to this lead.',
            decline: 'Decline',
            email_choice: 'At what address would you like to send an email?',

            none_selected: 'No selection',
            selected: 'selected',
            all_selected: 'All selected',
            select_all: 'Select all',
            search: 'Search',

            no_phone: 'Lead has no valid phone',
            no_phone_provider: 'Lead has no valid phone provider',
            no_email: 'Lead has no valid email',
            delivered: '@.capitalize:lexicon.delivered.singular',
            presented: 'Show',
            virtualDone: 'Call completed',
            appointmentCallTooltip: 'Call appointment',
            otherVisitTooltip: 'Other visit',
            saleByPhoneTooltip: '@.capitalize:lexicon.sale.singular By Phone',
            deliveredTooltip: 'Client showed',
            presentedTooltip: '@.capitalize:lexicon.delivered.singular vehicle',
            readOnlyNotAllowed: 'You are not allowed to edit this event',
            saleIsNeededForDelivery: 'You can not add a @:lexicon.delivery.singular for a lead that has not been @:lexicon.sold.singular',
            renewalIsNeededForDelivery: 'You can not add a @:lexicon.delivery.singular for a lead that has not been @:lexicon.renewed.singular',
            hasDeliveryTask: 'There is already a registered @:lexicon.delivery.singular for this lead',

            noShowTooltip: 'No show client',
            noShow: 'No show',
            addEventTitle: 'Add an appointment',
            addTaskTitle: 'Add a task',
            addTaskEventTitle: 'Add a task or appointment',
            vehicleDamageCostTitle: 'Vehicle damage cost',

            priorities: {
                normal: 'Normal',
                high: 'High',
            },
            subscribeToSharedView: 'Subscribe to shared view',
            subscribeToSharedViewTootlip: 'Allows you to decide if you want to subscribe to this shared view',

            updateDashboardViewTitle: 'Save this view',
            updateDashboardViewContent: 'Would you like to save this view with your new selected filters?',

            closingCampaignCreation: 'All data will be lost for this campaign',

            overlappingTaskEventTitle: 'This event overlaps one or more other events',
            overlappingTaskEventList: '{0}<br/>{1} to {2}',
            overlappingTaskEventConfirmation: 'Do you still want to proceed?',
        },
        searchLead: {
            chooseALead: 'Choose a lead',
            chooseLead: 'Choose this lead',
            client_name: 'Client name',
            clients: 'Client | Clients',
            date: 'Date',
            division: 'Division',
            email: 'Email',
            lead_type: 'Lead type',
            loyalty: 'Loyalty',
            other: 'Other',
            phone: 'Phone',
            renewal: '@.capitalize:lexicon.portfolio.singular',
            tableFooter: 'Showing {limit} leads out of {leadCount}',
            title: 'Search a client',
            wanted: 'Wanted',
            walk_in: 'Walk-in',
            exchange: 'Actual',
        },
        email: {
            title: 'Web Lead',
            title_sent: 'Email sent',
            response: 'Response',
            prefix: 'Prefix',
            prefix_mr: 'Mr',
            prefix_ms: 'Ms',
            prefix_none: '- None -',
            response_template: 'Response type',
            response_phrase: 'Phrase',
            content: 'Content',
            send: 'Send',
            result: 'Result',
            preview_message: 'Email preview',
            originalEmail: 'Original email',
            no_original_email: 'No original email',
            custom_price: 'Vehicle price',
            no_custom_price: 'No price',
            price_here: 'Price here',
            vehicle_here: 'Vehicle here',
            phrase_here: 'Phrase here',
            link_here: 'Link here',
            interested_by_here: 'Interested by here',
            error: 'Error',
            is_required: ' is required',
            incomplete_fr_response: 'The email is exclusively composed in english. Do you still want to proceed?',
            incomplete_en_response: 'The email is exclusively composed in french. Do you still want to proceed?',
            success: 'The email is being sent',
            success_mass_mailing: 'Your emails are being sent',
            lead_locale: 'Lead language',
            unauthorized: 'You are not allowed to send this email',
            datetime: 'Date & time',
            datetime_here: 'Date & time here',
            other: '- Other -',
            interested_by: 'Interested by',
            subject: 'Subject',
            body: 'Body',
            user_not_found_title: 'Email Not Found',
            user_not_found_body: "Greetings :clientName,<br><br>We are sorry to inform you that the email address ':userEmail' at which you send the below email is not valid.<br><br>Please reply directly from the original email.<br><br>Thank you<br><br>--<br><br>:sentEmail",
            already_assigned_title: 'Already answered',
            already_assigned_text: 'This request was answered by :advisorName',
            temp_assigned_title: 'Temporarily reserved',
            temp_assigned_text: 'This request is temporarily reserved for :advisorName',
            temp_assigned_text_2: 'You can try again in 5 minutes',
            time_remaining: 'Reserved for',
            time_elapsed_title: 'Time elapsed',
            time_elapsed_text: 'Your time is up',
            time_elapsed_text_2: 'You can try again by <a onclick="window.history.back();">clicking here</a>',
            forward: 'This email was forwarded from',
            custom_email: 'Custom',
            attachment: 'Attachment',
            divisions: 'Divisions',
            vehicle: 'Vehicle',
            vehicle_link: 'Vehicle lien',
            prefix_mr_fr: 'M.',
            prefix_mr_en: 'Mr',
            prefix_ms_fr: 'Mme',
            prefix_ms_en: 'Ms',
            prefix_none_fr: '- Aucun -',
            prefix_none_en: '- None -',
            error_unknown: 'An unknown error has occurred. Please <a href="https://activixinc.freshdesk.com/en/support/tickets/new">contact support</a>.',
            leadxpress_unauthorized: 'You are not allowed to send this email',
            feminine: '',
            savedDraft: 'Draft saved successfully',
            on: 'On',
            wrote: 'wrote',
        },
        facebook: {
            modal: {
                noReply: '* To answer the conversation, you must use Facebook Messenger.',
            },
            image: 'Image',
        },
        allLead: {
            allFollowedTooltip: 'Communications done within the date range for the leads & clients shown',
            allFollowedTooltipDates: 'Communications done within the date range',
            appointmentsTooltip: 'Represents the total number of appointments (appointments, test drives, deliveries) within the date range',
            averageContact: 'Contact',
            averageContactTooltip: 'Average of email exchanges and phone calls + 60sec between the lead or client and the user',
            allLeadsFilteredTooltipOn: 'Include all clients',
            allLeadsFilteredTooltipOff: 'Exclude all @.capitalize:lexicon.portfolio.singular / Loyalty clients without activities or with an end of contract date more than 3 months away',
            updatedBySupplier: 'Updated by supplier',
            averageFollowed: 'Comm.',
            averageFollowedTooltip: 'Avg. number of communications by lead & client',
            clients: 'Client | Clients',
            clientsRenewalLoyalty: '@.capitalize:lexicon.portfolio.singular & loyalty',
            clientsRenewal: '@.capitalize:lexicon.portfolio.singular | @.capitalize:lexicon.portfolio.plural',
            clientsPhoneUp: 'Phone-Up',
            clientsUp: '@.capitalize:lexicon.walkIn.singular & Phone-up',
            clientsWeb: 'Web lead | Web leads',
            clientsWebAndOrder: 'Web Leads & Orders',
            delivered: '@.capitalize:lexicon.delivered.singular',
            deliveries: '@.capitalize:lexicon.delivered.singular vehicle | @.capitalize:lexicon.delivered.plural vehicles',
            deliveriesBack: '@.capitalize:lexicon.delivered.singular vehicles',
            deliveriesPopover: '@.capitalize:lexicon.delivered.singular vehicles on the number of total @:lexicon.sale.plural',
            options: {
                enableResultStat: 'Activate to filter on creation date, end contract date or results (checkbox)',
            },
            PhoneUpOrder: '@.capitalize:lexicon.sale.singular Phone-Up | @.capitalize:lexicon.sale.plural Phone-Up',
            receivedOrder: 'Received @.capitalize:lexicon.sale.singular |Received @.capitalize:lexicon.sale.plural ',
            renewalOrders: '@.capitalize:lexicon.portfolio.singular @.capitalize:lexicon.sale.plural',
            sales: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesBack: 'Total @:lexicon.sale.plural',
            salesBackDeposit: 'Total @:lexicon.sale.plural with deposit',
            salesPhoneUp: 'Total @:lexicon.sale.plural from Phone-Up',
            salesRenewalLoyalty: '@.capitalize:lexicon.portfolio.singular & loyalty @:lexicon.sale.singular | @.capitalize:lexicon.portfolio.singular & loyalty @:lexicon.sale.plural',
            salesRenewalLoyaltyBack: 'Total @:lexicon.portfolio.singular & loyalty @:lexicon.sale.plural',
            salesUp: 'Up @:lexicon.sale.singular | Up @:lexicon.sale.plural',
            salesWeb: 'Web @:lexicon.sale.singular | Web @:lexicon.sale.plural',
            salesWebBack: 'Total web @:lexicon.sale.plural',
            sourceTestDrive: 'Test drive',
            sourceOther: 'Other',
            tradeReport: 'Acquisitions Report',
            visitWithoutAppointmentTooltip: 'Number of visits without appointment on the number of assigned leads',
            walkinLeads: '@.capitalize:lexicon.walkIn.singular | @.capitalize:lexicon.walkIn.plural',
            walkInOrder: '@.capitalize:lexicon.walkIn.abr @.capitalize:lexicon.sale.singular | @.capitalize:lexicon.walkIn.abr @.capitalize:lexicon.sale.plural',

            hideNoLeadAccount: 'Hide accounts with no leads',
            hideNoLeadAdvisor: 'Hide associates with no leads',
            showNoLeadAccount: 'Show accounts with no leads',
            showNoLeadAdvisor: 'Show associates with no leads',
        },
        loyalty: {
            appointment: 'Appointment | Appointments',
            appointmentCall: 'Phone appointments',
            appointmentCallTooltipOff: 'Click here to show stats for phone appointments',
            appointmentCallTooltipOn: 'Click here to show stats for in dealership appointments',
            clients: 'Clients',
            delivered: '@.capitalize:lexicon.delivered.singular vehicle | @.capitalize:lexicon.delivered.plural vehicles',
            deliveredCardBack: '@.capitalize:lexicon.delivered.singular vehicles',
            otherVisitTooltipOff: 'Click here to show stats for other visits',
            otherVisitTooltipOn: 'Click here to show stats for visits at the dealership',
            phoneAppointment: 'Phone appointments',
            phoneAppointmentCardBack: 'Total number of appointments',
            phoneAppointmentTooltipOff: 'Click here to show stats for phone appointments',
            phoneAppointmentTooltipOn: 'Click here to show stats for in dealership appointments',
            presented: 'Visit | Visits',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleCardBack: 'Total @:lexicon.sale.plural',
            visit: 'Visit | Visits',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
        },
        renewal: {
            allFollowedTooltip: 'Communications done within the date range for the clients shown',
            appointment: 'Appointment | Appointments',
            averageContactTooltip:
                'Average of email exchanges and phone calls + 60sec between the client and the user',
            averageFollowedTooltip: 'Avg. number of communications by client',
            cash: 'Cash',
            clients: '@.capitalize:lexicon.portfolio.singular clients',
            delivered: '@.capitalize:lexicon.delivered.singular vehicle',
            disabledEndContractFilterOption: 'The end contract date must be the only date filter selected',
            financed: 'Financed',
            location: 'Lease | Leases',
            modality: 'Modality',
            modalityExchange: 'Modality exch.',
            negativeEquity: 'Negative equity',
            positiveEquity: 'Positive equity',
            presented: 'Visit | Visits',
            renewalType: '@.capitalize:lexicon.portfolio.singular type',
            saleCardBack: 'Total @:lexicon.renewed.singular',
            sold: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            soldBy: '@.capitalize:lexicon.sold.singular by',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
            visitWithoutAppointmentTooltip: 'Number of visits without appointment on the number of assigned clients',

            hideNoLeadAccount: 'Hide accounts with no clients',
            hideNoLeadAdvisor: 'Hide associates with no clients',
            showNoLeadAccount: 'Show accounts with no clients',
            showNoLeadAdvisor: 'Show associates with no clients',
        },
        event: {
            appointment: 'Appointment | Appointments',
            apptCall: 'Phone appt. | Phone appts.',
            apptFilterExcludesCall: '(phone excluded)',
            apptNoDate: 'Without date',
            apptNoTime: 'Without time',
            leadToReach: 'Associate must call',
            lostBack: 'Total lost',
            potentialAppointment: 'Potential appointment | Potential appointments',
            presented: 'Visit | Visits',
            reached: 'Reached | Reached',
            reachedBack: 'Total reached',
            saleCardBack: 'Total @:lexicon.sale.plural',
            uncertainLead: 'Undecided client | Undecided clients',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
        },
        webOrder: {
            allFollowedTooltip: 'Communications done within the date range for the orders shown',
            appointment: 'Appointment | Appointments',
            attempted: 'Attempt | Attempts',
            attemptedBack: 'of orders processed',
            averageContactTooltip:
                'Average of email exchanges and phone calls + 60sec between the lead and the user',
            averageFollowedTooltip: 'Avg. number of communications by order',
            delivered: '@.capitalize:lexicon.delivered.singular vehicle | @.capitalize:lexicon.delivered.plural vehicles',
            deliveredBack: '@.capitalize:lexicon.delivered.singular vehicles',
            deliveryFailed: 'Failed to send email',
            firstUpdateAverage: 'Average first update time',
            leads: 'Order | Orders',
            loadingGraphs: 'Rendering graphs...',
            parent: 'Parent',
            presented: 'Visit | Visits',
            reached: 'Reached | Reached',
            reachedAverage: 'Average first response time',
            reachedBack: 'of the leads reached',
            reachedBackShort: 'of reached',
            resultTimeInfo:
                '<strong>First response time :</strong><br>First communication from CRM, not applicable if the first communication is manual or automated. <br><br><strong>First update time :</strong><br>First update of the detailed card (all fields).<br><br><em>Times account for dealership opening hours.</em>',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesOverLeads: ' @:lexicon.sale.plural / Orders',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visits',
            untreated: 'Pending | Pending',
            untreatedBack: 'of the orders pending',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
        },
        leadXpress: {
            allFollowedTooltip: 'Communications done within the date range for the leads shown',
            appointment: 'Appointment | Appointments',
            attempted: 'Attempt | Attempts',
            attemptedBack: 'of leads processed',
            averageContactTooltip:
                'Average of email exchanges and phone calls + 60sec between the lead and the user',
            averageFollowedTooltip: 'Avg. number of communications by lead',
            delivered: '@.capitalize:lexicon.delivered.singular vehicle | @.capitalize:lexicon.delivered.plural vehicles',
            deliveredBack: '@.capitalize:lexicon.delivered.singular vehicles',
            deliveryFailed: 'Failed to send email',
            deposit: 'Deposits',
            firstUpdateAverage: 'Average first update time',
            leads: 'Lead | Leads',
            longTerm: 'Long term',
            lost: 'Lost | Losts',
            parent: 'Parent',
            presented: 'Visit | Visits',
            reached: 'Reached | Reached',
            reachedAverage: 'Average first response time',
            reachedBack: 'of the leads reached',
            resultTimeInfo:
                '<strong>First response time :</strong><br>First communication from CRM, not applicable if the first communication is manual or automated.<br><br><strong>First update time :</strong><br>First update of the detailed card (all fields).<br><br><em>Times account for dealership opening hours.</em>',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesOverLeads: ' @.capitalize:lexicon.sale.plural / Leads',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visits',
            untreated: 'Pending | Pending',
            untreatedBack: 'of the leads pending',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
        },
        webBoost: {
            appointment: 'Appointment | Appointments',
            averageTimeHeader: 'Average time',
            businessHours: 'Lead within business hours | Leads within business hours',
            businessHoursBack: 'of leads within business hours',
            confirmRevive: 'Are you sure you want to revive the WebBoost call?',
            leads: 'Lead | Leads',
            leadsReached: 'Lead reached | Leads reached',
            leadsReachedBack: 'Leads reached with WebBoost',
            leadsTreated: 'Lead processed | Leads processed',
            leadsTreatedBack: 'Leads processed through WebBoost',
            reachedTime: 'Average time',
            reachedTimeBack: "Average time between the lead's request and the first contact attempt",
            revive: 'Revive',
            reviveWebBoost: 'Revive the WebBoost call',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleBack: 'Total @.capitalize:lexicon.sale.plural',
            saleHeader: '@.capitalize:lexicon.sale.plural',
            treatedHeader: 'Processed',
            validPhone: 'Lead with a valid phone # | Leads with a valid phone #',
            validPhoneBack: 'of the leads had a valid phone #',
        },
        phoneUp: {
            allFollowedTooltip: 'Communications done within the date range for the Phone-up shown',
            answered: 'Call answered | Calls answered',
            appointment: 'Appointment | Appointments',
            assigned: 'Calls answered',
            averageContactTooltip:
                'Average of email exchanges and phone calls + 60sec between the Phone-up and the user',
            averageFollowedTooltip: 'Avg. number of communications by Phone-up',
            callBy: 'Calls by ',
            clientAuto: 'Added automatically | Added automatically',
            clientManual: 'Added manually | Added manually',
            clients: 'Call received | Calls received',
            delivered: '@.capitalize:lexicon.delivered.singular vehicle',
            phoneUp: 'Phone-up',
            presented: 'Visit | Visits',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleCardBack: 'Total @.capitalize:lexicon.sale.plural',
            salesOverCalls: '@.capitalize:lexicon.sale.plural / Answered',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visits',
            viaIvr: 'Call via IVR | Calls via IVR',
            visitWithAppointment: 'With appt.',
            visitWithoutAppointment: 'Without appt.',
            waitTime: 'Average time',
            waitTimeCardBack: 'Average time between the receptionist and the associate',
            visitWithoutAppointmentTooltip: 'Number of visits without appointment on the number of assigned Phone-up',

            hideNoLeadAccount: 'Hide accounts with no calls',
            hideNoLeadAdvisor: 'Hide associates with no calls',
            showNoLeadAccount: 'Show accounts with no calls',
            showNoLeadAdvisor: 'Show associates with no calls',
        },
        walkIn: {
            allFollowedTooltip: 'Communications done within the date range for the @:lexicon.walkIn.prefix shown',
            answered: 'Calls answered',
            appointment: 'Appointments',
            assigned: '@.capitalize:lexicon.walkIn.singular',
            averageContactTooltip:
                'Average of email exchanges and phone calls + 60sec between the @:lexicon.walkIn.singular and the user',
            averageFollowedTooltip: 'Avg. number of communications by @:lexicon.walkIn.singular',
            beBack: 'B-Back | B-Backs',
            beBackCardBack: 'Clients that visited the dealership more than once',
            beBackSaleCardBack: '@.capitalize:lexicon.sale.singular from clients that visited the dealership more than once | @.capitalize:lexicon.sale.plural from clients that visited the dealership more than once',
            clients: 'Phone-up',
            csiPopover: 'CSI on the number of @.capitalize:lexicon.delivered.plural vehicles',
            delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural ',
            deliveredVehicle: '@.capitalize:lexicon.delivered.singular vehicle | @.capitalize:lexicon.delivered.plural vehicles',
            deliveredCardBack: '@.capitalize:lexicon.delivered.singular vehicles',
            freshUp: 'Fresh-Up',
            freshUpBack: 'Clients that visited the dealership',
            ofCsi: 'of CSI completed',
            ofRoadTest: 'of test drives',
            ofTakeOver: 'of turn over',
            ordersUp: '@.capitalize:lexicon.sale.singular Up | @.capitalize:lexicon.sale.plural Up',
            ordersUpDescription: '@.capitalize:lexicon.sale.singular that required only one visit | @.capitalize:lexicon.sale.plural that required only one visit ',
            roadTest: 'Test drive | Test drives',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleBeBack: '@.capitalize:lexicon.sale.plural B-Back',
            saleBeBackCardBack: '@.capitalize:lexicon.sale.singular from clients that visited the dealership more than once | @.capitalize:lexicon.sale.plural from clients that visited the dealership more than once',
            saleCardBack: 'Total @.capitalize:lexicon.sale.plural',
            saleFromWalkin: 'Total @:lexicon.sale.plural from @.capitalize:lexicon.walkIn.singular',
            saleUp: '@.capitalize:lexicon.sale.singular Up | @.capitalize:lexicon.sale.plural Up',
            saleUpCardBack: '@.capitalize:lexicon.sale.plural that required only one visit',
            takeOver: 'Take over',
            totalOrders: 'Total @:lexicon.sale.singular | Total @:lexicon.sale.plural',
            totalOrdersBack: 'Total of @:lexicon.sale.singular for @.capitalize:lexicon.walkIn.singular | Total of @:lexicon.sale.plural for @.capitalize:lexicon.walkIn.singular',
            walkIn: '@.capitalize:lexicon.walkIn.singular | @.capitalize:lexicon.walkIn.plural',

            hideNoLeadAccount: 'Hide accounts with no leads',
            hideNoLeadAdvisor: 'Hide associates with no leads',
            showNoLeadAccount: 'Show accounts with no leads',
            showNoLeadAdvisor: 'Show associates with no leads',
        },
        database: {
            male: 'Male',
            female: 'Female',
            fr: 'French',
            en: 'English',
            mr: 'Mr.',
            ms: 'Ms.',
            mrs: 'Mrs.',
            miss: 'Miss',
            dr: 'Dr.',
            me: 'Esq.',

            // Male posts
            post_account_manager: 'Account Manager',
            post_admin: 'Administrator',
            post_agent: 'Agent',
            post_analyst: 'Analyst',
            post_coach: 'Coach',
            post_director: 'Director',
            post_other: 'Other',
            post_programmer: 'Programmer',

            post_advisor: 'Sales Associate',
            post_assistant_director: 'Assistant Director',
            post_assistant_manager: 'Assistant Manager',
            post_assistant_sales_director: 'Assistant Sales Director',
            post_assistant_sales_manager: 'Assistant Sales Manager',
            post_bdc_agent: 'BDC Agent',
            post_chief_director: 'General Manager',
            post_chief_sales_director: 'General Sales Manager',
            post_commercial: 'F&I',
            post_commercial_director: 'F&I',
            post_commercial_manager: 'Financial Manager',
            post_commercial_vehicle_director: 'Financial Vehicles Director',
            post_commercial_vehicle_manager: 'Financial Vehicles Manager',
            post_controller: 'Controller',
            post_delivery_man: 'Delivery Man',
            post_director_bdc: 'BDC Director',
            post_director_renewal: 'Renewal Director',
            post_internet_sales_director: 'Internet Sales Director',
            post_internet_sales_manager: 'Internet Sales Manager',
            post_internet_seller: 'Internet Sales Associate',
            post_internet_specialist: 'Internet Sales Specialist',
            post_internet_specialist_2: 'Internet Specialist',
            post_manager: 'Manager',
            post_manager_bdc: 'BDC Manager',
            post_manager_renewal: 'Renewal Manager',
            post_owner: 'Owner',
            post_product_advisor: 'Product Advisor',
            post_product_specialist: 'Product Specialist',
            post_product_specialist_2: 'Product Genius',
            post_receptionist: 'Receptionist',
            post_relationist: 'Relationist',
            post_representative: 'Sales Representative',
            post_sales_assistant: 'Sales Assistant',
            post_sales_director: 'Sales Director',
            post_sales_manager: 'Sales Manager',
            post_service_advisor: 'Service Advisor',
            post_service_agent: 'Customer Service Agent',
            post_service_director: 'Service Director',
            post_service_manager: 'Service Manager',
            post_technical_advisor: 'Technical Advisor',
            post_web_director: 'Web Director',
            post_web_manager: 'Web Manager',
            post_web_sales_advisor: 'Web Sales Associate',
            post_web_specialist: 'Web Specialist',

            // Female posts
            post_account_manager_w: 'Account Manager',
            post_analyst_w: 'Analyst',
            post_coach_w: 'Coach',
            post_other_w: 'Other',
            post_programmer_w: 'Programmer',

            post_advisor_w: 'Sales Associate',
            post_assistant_director_w: 'Assistant Director',
            post_assistant_manager_w: 'Assistant Manager',
            post_assistant_sales_director_w: 'Assistant Sales Director',
            post_assistant_sales_manager_w: 'Assistant Sales Manager',
            post_bdc_agent_w: 'BDC Agent',
            post_chief_director_w: 'General Manager',
            post_chief_sales_director_w: 'General Sales Manager',
            post_commercial_director_w: 'F&I',
            post_commercial_manager_w: 'Financial Manager',
            post_commercial_vehicle_director_w: 'Financial Vehicles Director',
            post_commercial_vehicle_manager_w: 'Financial Vehicles Manager',
            post_commercial_w: 'F&I',
            post_controller_w: 'Controller',
            post_delivery_woman: 'Delivery Woman',
            post_director_bdc_w: 'BDC Director',
            post_director_renewal_w: 'Renewal Director',
            post_internet_sales_director_w: 'Internet Sales Director',
            post_internet_sales_manager_w: 'Internet Sales Manager',
            post_internet_seller_w: 'Internet Sales Associate',
            post_internet_specialist_2_w: 'Internet Specialist',
            post_internet_specialist_w: 'Internet Seller Specialist',
            post_manager_bdc_w: 'BDC Manager',
            post_manager_renewal_w: 'Renewal Manager',
            post_manager_w: 'Manager',
            post_owner_w: 'Owner',
            post_product_advisor_w: 'Product Advisor',
            post_product_specialist_2_w: 'Product Genius',
            post_product_specialist_w: 'Product Specialist',
            post_receptionist_w: 'Receptionist',
            post_relationist_w: 'Relationist',
            post_representative_w: 'Sales Representative',
            post_sales_assistant_w: 'Sales Assistant',
            post_sales_director_w: 'Sales Director',
            post_sales_manager_w: 'Sales Manager',
            post_service_advisor_w: 'Service Advisor',
            post_service_agent_w: 'Customer Service Agent',
            post_service_director_w: 'Service Director',
            post_service_manager_w: 'Service Manager',
            post_technical_advisor_w: 'Technical Advisor',
            post_web_director_w: 'Web Director',
            post_web_manager_w: 'Web Manager',
            post_web_sales_advisor_w: 'Web Sales Associate',
            post_web_specialist_w: 'Web Specialist',

            role_admin: 'Administrator',
            role_advisor: 'Associate',
            role_agent: 'BDC Agent',
            role_analyst: 'Analyst',
            role_commercial: 'F&I',
            role_director: 'Manager',
            role_director_bdc: 'BDC Manager',
            role_manager: 'CRM Manager',
            role_programmer: 'Programmer',
            role_service_advisor: 'Service Advisor',
            role_service_agent: 'Service Agent',

            role_admin_w: 'Administrator',
            role_advisor_w: 'Associate',
            role_agent_w: 'BDC Agent',
            role_analyst_w: 'Analyst',
            role_commercial_w: 'F&I',
            role_director_bdc_w: 'BDC Manager',
            role_director_w: 'Manager',
            role_manager_w: 'CRM Manager',
            role_programmer_w: 'Programmer',
            role_service_advisor_w: 'Service Advisor',
            role_service_agent_w: 'Service Agent',

            lead_type_email: 'Web Lead',
            lead_type_phone: 'Phone',
            lead_type_walk_in: '@.capitalize:lexicon.walkIn.singular',
            lead_type_loyalty: 'Loyalty',
            lead_type_renewal: '@.capitalize:lexicon.portfolio.singular',
            lead_type_event: 'Event',
            lead_type_sms: 'SMS',
            lead_type_pre_booking: 'Pre-booking',
            lead_type_dms: 'DMS',
            lead_type_web_order: 'Web Order',

            lead_vehicle_type_exchange: 'Current',
            lead_vehicle_type_wanted: 'Wanted',
        },
        configMailClient: {
            v3: {
                status: 'Status',
                provider: 'Provider',
                grantStatus: 'Connection Status',
                activatedOn: 'Activated On',
                email: 'Email',
                valid: 'Valid',
                invalid: 'Invalid',
                404: 'Unavailable',
            },
            activate: 'Activate the email client',
            reActivate: 'Re-activate the email client',
            reAuthenticate: 'Re-authenticate the email client',
            alreadyActive: 'The email client is already activated',
            deactivate: 'Deactivate the email client',
            deactivateSuccess: 'The email client was deactivated',
            deactivateFailure: 'The email client could not be deactivated',
            reactivateSuccess: 'The email client was re-activated',
            error: 'An error has occured',
            revalidate: 'Revalidate your email client information',
        },
        support: {
            success: 'Your request was sent successfully',
        },
        mailClient: {
            reset: 'Reset',
            draftDate: 'Draft date',
            activixSupport: 'Activix support',
            message: 'Message',
            attachments: 'Attached files',
            url: 'URL',
            customerService: 'Customer service',
            missingConfig: 'You must first configure your mailbox.',
            importingEmails: 'We are currently importing your emails. Please try again in a few minutes.',
            composerMessage: 'Create a new message',
            editMessage: 'Edit message',
            showLeads: 'Show the lead | Show the leads',
            removedFromMassMailing:
                '{0} lead was removed from the mass mailing list because a user is currently writing him/her an email or did in the last 10 minutes | {0} leads were removed from the mass mailing list because a user is currently writing them an email or did in the last 10 minutes',
            removedFromMassMailingDoNotMarket:
                '{0} lead was removed from the mass mailing list because this lead is listed as do not market. | {0} leads were removed from the mass mailing list because they are listed as do not market',
            contactedBy: 'Contacted by {0}',
            mailClientNotConfigured: 'Mailbox not configured. Please configure it before trying to send an email',
            blackListed: 'is of a blacklisted type',
            totalAttachmentExceed: 'The total size of the attachment can not exceed',
            emptyBody: 'Empty description',
            emptyBodyQuestion: 'The description is empty, do you really want to send this email?',
            emptySubject: 'Empty subject',
            emptySubjectQuestion: 'The subject is empty, do you really want to send this email?',
            error: 'Error',
            errorSendingMessage: 'Error sending message.',
            draftSaved: 'Draft saved correctly.',
            deleteEmailConfirmation: 'Do you really want to delete this email, it will be gone forever!',
            deleteContactConfirmation: 'Do you really want to delete this contact?',
            discardIt: 'Yes, delete it!',
            saveDraft: 'Do you want to save your draft?',
            saveDraftQuestion: 'Do you want to save this email as a draft?',
            readMail: 'Read Mail',
            reply: 'Reply',
            replyAll: 'Reply All',
            forward: 'Forward',
            from: 'From',
            to: 'To',
            noSubject: 'No subject',
            delete: 'Delete',
            archive: 'Archive',
            print: 'Print',
            errorArchive: 'Error while archiving email.',
            errorDeleting: 'Error while deleting email.',
            emailNotFound: 'Email not found.',
            compose: 'Compose',
            generalFolder: 'General Folders',
            inbox: 'Inbox',
            sent: 'Sent',
            drafts: 'Drafts',
            spam: 'Junk',
            trash: 'Trash',
            archive2: 'Archive',
            otherFolders: 'Other Folders',
            loading: 'Loading',
            backInbox: 'Back to inbox',
            noEmail: 'No emails',
            errorConnectingFolderTitle: 'Error connecting to folder!',
            errorConnectingFolder:
                'Please make sure you have a valid imap connection & you mapped your folders correctly.',
            errorMoving: 'Error while transfering email',
            errorAttachment: 'Error while saving attachment',
            pressEnterSelect: 'Press enter to select',
            selected: 'Selected',
            pressEnterRemove: 'Press enter to remove',
            draft: 'Draft',
            send: 'Send',
            addAttachment: 'Attachment',
            max: 'Max',
            discard: 'Delete',
            editContactInfo: 'Update contact infos',
            name: 'Name',
            emailAddress: 'Email',
            enterValidEmail: 'Please enter a valid email',
            notPreferredEmail: 'Preferred email address not selected',
            close: 'Close',
            save: 'Save',
            subject: 'Subject',
            cc: 'CC',
            bcc: 'BCC',
            all: 'All messages',
            success: 'The email was sent successfully',
            emptySubjectTooltip: 'The subject is required',
            emptyRecipientTooltip: 'You must enter at least one recipient',
            emptyBodyTooltip: 'The body of the email cannot be empty',
            emptySenderTooltip: 'The sender cannot be empty',
            emptyFallbackSenderTooltip: 'The fallback sender cannot be empty',
            emptyDivisionTooltip: 'The division is required',
            emptyResponseTemplateTooltip: 'You must select a response template',
            forwarding: 'Forward',
            enterCcRecipients: 'Add CC recipients',
            enterBccRecipients: 'Add BCC recipients',
            enterForwardRecipients: 'Add recipients',
            videoUploading: 'Wait for video to finish uploading',
        },
        saleTable: {
            availableDate: 'Veh. here on',
            averageValue: 'Avg. goal / unit',
            carryOverTooltipOff: 'Click here to hide Carry Overs',
            carryOverTooltipOn: 'Click here to show Carry Overs',
            color: 'Color',
            commercial: 'F&I',
            creditApproved: 'Credit approved',
            creditApprovedBack: 'Number of clients with approved credit',
            deliverableDate: 'Veh. @:lexicon.deliverable.singular on',
            deliveredBack: '@.capitalize:lexicon.delivered.plural vehicles',
            deliveredDate: '@.capitalize:lexicon.delivered.singular',
            deliveries: '@.capitalize:lexicon.delivery.singular | @.capitalize:lexicon.delivery.plural',
            delivery: '@.capitalize:lexicon.delivery.plural',
            deliveryBack: 'Number of @:lexicon.sale.plural @.capitalize:lexicon.delivered.singular to clients or with a @.capitalize:lexicon.delivery.singular date planned',
            deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
            deliveryMonth: '@.capitalize:lexicon.delivery.plural in current month',
            deliveryPostponed: 'Future @:lexicon.delivery.plural (Postponed)',
            displayAllUsersEyeBlueToolTip: 'Show all sellers including those who have no @:lexicon.sale.singular and no objective',
            displayUsersWithSaleOrObjectivesEyeToolTip: 'Only show sellers with @:lexicon.sale.plural or objectives',
            gas: 'Fueled',
            inStock: 'In stock',
            inStockBack: 'Number of in stock @:lexicon.sale.plural',
            inTransit: 'In transit',
            lastMonthSales: "Previous month's @:lexicon.sale.plural",
            locate: 'Locate',
            locates: 'Locates',
            monthStartDayTooltip: 'The day of the month that your @:lexicon.sale.plural month starts at (For objectives only)',
            new: 'New',
            new_and_used: 'new & used',
            newShort: 'N',
            nextStep: 'Next step: ',
            noAdvisorFistName: 'No',
            noAdvisorLastName: 'Associate',
            noDivisionShort: '?',
            notAvailable: 'Not Avail.',
            objective: 'Objective',
            objectiveReached: 'Goal achieved',
            objectiveReachedDeliveries: '@.capitalize:lexicon.delivery.plural goal achieved',
            offer: 'Offer #',
            order: 'Order',
            orders: 'Orders',
            ordersNotDelivered: '@.capitalize:lexicon.undelivered.plural orders of any date',
            progressStateTooltip: 'Approbation process',
            recordedDate: 'Reported',
            recordedDateTooltipOff: 'Click here to filter using @:lexicon.sale.singular dates',
            recordedDateTooltipOn: 'Click here to filter using reported dates',
            saleAmmount: 'Goal',
            saleJournal: '@.capitalize:lexicon.sale.plural and @:lexicon.delivery.singular table',
            stock: 'In stock',
            stockSold: 'In stock @:lexicon.sold.singular',
            suffix: 'Suffix',
            teamAverage: 'Team %',
            total: 'Total',
            totalAvePct: 'Total / Average / %',
            transmission: 'Transmission',
            unknown: 'Unknown',
            used: 'Used',
            usedShort: 'U',
            user: 'Associate',
            waitingSale: 'Waiting @:lexicon.sale.singular',
            waitingSaleForce: 'Manual waiting @:lexicon.sale.singular',
            waitingSaleNoAssigned: 'Unassigned Waiting @:lexicon.sale.singular',
            waitingSaleNoAssignedShort: 'ø assigned',
            waitingSaleTotal: 'Total waiting @:lexicon.sale.plural',
            withoutDivision: 'No division',
            yourObjective: 'Your goal',

            profitAndAverage: {
                tooltip: {
                    byDeliveredDate: 'Click here to use @:lexicon.delivered.singular date',
                    byRecordedDate: 'Click here to use recorded date',
                    bySaleDate: 'Click here to use @:lexicon.sale.singular date',
                    disabledByRecordedDate: 'By recorded date',
                },
            },

            cards: {
                approved: 'Approved | Approved',
                carryOver: 'Carry Over | Carry Overs',
                carryOverBack: '@.capitalize:lexicon.delivered.singular @:lexicon.sale.plural from previous months or with @:lexicon.delivery.plural scheduled within the selected dates',
                carryOverNotDelivered: 'Carry Over not @:lexicon.delivered.singular | Carry Overs not @:lexicon.delivered.plural',
                carryOverPlanned: 'Carry Over planned | Carry Overs planned',
                delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural',
                deliveredBack: '@.capitalize:lexicon.delivered.plural vehicles',
                delivery: {
                    inDate: '@.capitalize:lexicon.delivery.singular in date | @.capitalize:lexicon.delivery.plural in date',
                    inFuture: 'Future @:lexicon.delivery.singular | Future @:lexicon.delivery.plural',
                },
                lost: 'Lost | Lost',
                lostBack: 'Clients lost or with a credit refusal',
                lostByClient: 'Lost / client | Losts / client',
                lostByDealer: 'Lost / dealer | Losts / dealer',
                planned: 'Planned | Planned',
                plannedBack: 'Scheduled @:lexicon.delivery.plural',
                plannedTooltip: 'Number of @:lexicon.sale.plural with @:lexicon.delivery.plural scheduled between the selected dates',
                postponed: 'Postponed | Postponed',
                postponedBack: '@.capitalize:lexicon.delivery.plural postponed',
                postponedTooltip: 'Number of @:lexicon.delivery.plural postponed after the selected dates',
                recorded: 'Reported | Reported',
                recordedBack: 'Number of reported vehicles between the selected dates',
                refused: 'Refused | Refused',
                sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
                saleAndCarryOver: '@.capitalize:lexicon.sale.singular & Carry Over | @.capitalize:lexicon.sale.plural & Carry Overs',
            },
            filters: {
                approved: 'Approved',
                carryOver: 'Carry Over',
                carryOverNotDelivered: 'Carry Over not delivered',
                delivered: '@.capitalize:lexicon.delivered.singular',
                deliveryCarryOver: 'Carry Over planned',
                discounted: 'Funded',
                lost: 'Lost',
                manualWaitingSale: 'Manual waiting @:lexicon.sale.singular',
                none: 'None',
                planned: 'Planned',
                postponed: 'Postponed',
                refused: 'Refused',
                sold: '@.capitalize:lexicon.sold.singular',
                waitingSale: 'Waiting @:lexicon.sale.singular',
            },
            gridReport: {
                carView: 'Car',
                divisionView: 'Division',
                nameView: 'Name',
                selectViewType: 'Select a view type',
                stockView: 'Stock #',
            },
            legendTooltips: {
                approved: '@.capitalize:lexicon.sale.singular approved in the selected dates but not planned',
                carryOver: '@.capitalize:lexicon.sold.singular prior to the selected dates but @:lexicon.delivered.singular within the selected dates',
                carryOverNotDelivered: '@.capitalize:lexicon.sold.singular prior to the selected dates but not @:lexicon.delivered.singular',
                carryOverPlanned: '@.capitalize:lexicon.sold.singular prior to the selected dates but planned within the selected dates',
                delivered: '@.capitalize:lexicon.sold.singular and @.capitalize:lexicon.delivered.singular in the selected dates',
                discounted: 'Funded',
                lost: 'Lead lost',
                planned: '@.capitalize:lexicon.sold.singular and planned within the selected dates',
                postponed: '@.capitalize:lexicon.sold.singular within the selected dates but planned after the selected dates',
                refused: '@.capitalize:lexicon.sale.singular refused',
                sales: '@.capitalize:lexicon.sold.singular within the selected dates but not planned',
                waitingSale: '@.capitalize:lexicon.sold.singular prior to the selected dates and without a planned @:lexicon.delivery.singular',
                waitingSaleForce: 'Sold prior to the selected dates and without a planned @:lexicon.delivery.singular - manually created by user',
            },
            months: {
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                mai: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December',
            },
            steps: {
                available: 'Arrival of vehi.',
                csi: 'CSI',
                deliverable: 'Vehicle can be @:lexicon.delivered.singular',
                delivered: '@.capitalize:lexicon.delivery.singular',
                delivery: '@.capitalize:lexicon.delivery.singular planning',
                gas: 'Fueled',
                inspected: 'Inspection',
                prepared: 'Preparation',
                recorded: 'Recording',
            },
            stickers: {
                approved: 'Approved',
                carryOver: 'Carry Over',
                delivered: '@.capitalize:lexicon.delivered.singular',
                deliveryCarryOver: 'Carry Over',
                carryOverNotDelivered: 'Carry Over',
                discounted: 'Funded',
                lost: 'Lost',
                none: 'None',
                planned: 'Planned',
                postponed: 'Postponed',
                refused: 'Refused',
                sold: '@.capitalize:lexicon.sold.singular',
                waitingSale: 'Waiting @:lexicon.sale.singular',
            },
            table: {
                accessory: 'Tires & accessories',
                accessoryAverage: 'Average',
                accessoryOnly: 'Accessories',
                accessoryProfit: 'Profit',
                advisors: 'Associates',
                commercial: 'F&I profits',
                commercialAverage: 'Average',
                commercialProfit: 'Profit',
                delivered: '@.capitalize:lexicon.delivered.singular',
                deliveries: '@.capitalize:lexicon.delivery.plural',
                goals: 'Goals',
                inTransit: 'In transit',
                lastMonth: 'null',
                locate: 'Locate',
                objective: 'Objectives',
                objectiveAverage: 'Average',
                objectiveProfit: 'Profit',
                objectiveSales: '@.capitalize:lexicon.sale.plural',
                order: 'Orders',
                previousSales: '@.capitalize:lexicon.sale.plural',
                profits: 'Profits',
                saleAverage: 'Average',
                saleProfit: 'Profit',
                sales: '@.capitalize:lexicon.sale.plural',
                sold: '@.capitalize:lexicon.sold.singular',
                stock: 'Stocks',
                tirePercent: 'Tires',
                total: 'Total',
                user: 'null',
                variation: 'Variation',
                variationTooltip: '@.capitalize:lexicon.sale.plural variation for the previous year based on the selected dates.<br><br>Formula:<br><span class="whitespace-nowrap">(Current - Previous) / Previous * 100</span>',
                vehicles: 'Vehicles',
            },
            tooltips: {
                accessoryAverage: 'Profit average from accessories @:lexicon.sold.singular over the number of @:lexicon.sale.plural',
                accessoryProfit: 'Total profit from accessories',
                carryOver: 'Carry overs are not included<br>in the @:lexicon.sale.singular total',
                commercialAverage: 'Profit average from the commercial division over the number of @:lexicon.sale.plural',
                commercialProfit: 'Total profit from products',
                waitingSale: 'Vehicles @:lexicon.sold.singular prior to the selected dates, not @:lexicon.delivered.singular and without a planned @:lexicon.delivery.singular',
            },
        },
        multiselect: {
            maximumReached: 'Maximum of {0} options selected. First remove a selected option to add another one.',
            noResult: 'No results...',
            pressEnterRemove: 'Press enter to remove',
            pressEnterSelect: 'Press enter to select',
            selected: 'Selected',
            selectOption: 'No selection',
        },
        groupManagement: {
            deleteGroup: 'Delete the group',
            relatedAccounts: 'Associated accounts',
            relatedUsers: 'Associated users',
        },
        phoneProvider: {
            addAlreadyBoughtNumber: 'Number already bought, please copy the number here to add it to the database',
            areaCode: 'Area code',
            custom: 'Custom',
            deletePhoneConfirmation:
                'If you delete this number, it will be impossible to get it back. Are you sure this number is no longer needed?',
            deletedExternalPhoneConfirmation:
                'Removing this number will not affect the number in the user\'s account. If the user changes their phone number, it will not be synchronized either.',
            direction: 'Direction',
            division: 'Division',
            deactivatePressToTalk:
                "By disabling this option, customer calls may fall on the user's voicemail, thus ending the customer call process.",
            deactivateIt: 'Deactivate',
            directionNotApplied: 'Direction is not applied for non-integrated phone provider',
            extension: 'Extension',
            ivr: 'IVR',
            montreal: 'Montreal',
            noAdvisor: 'No associate',
            noDivision: 'No division',
            noNumberAvailable: 'No number available',
            noSource: 'No source',
            nearOf: 'Near of',
            postalCode: 'Postal code',
            phone: 'Number',
            quebec: 'Quebec',
            region: 'Region',
            removeIt: 'Yes, remove it',
            source: 'Source',
            successfullyAdded: 'Number added successfully',
            title: 'Buy a phone number',
            tollFree: 'Tollfree',
            typeOfNumber: 'Type of numbers',
            user: 'User',
        },
        responseLeadXpress: {
            addAttachment: 'Attach',
            language: 'Language',
            alreadyCalled: '{0} already called the client {1}.',
            alreadyCalledLink: '{userName} has already called a <a target="_blank" href="{link}">client</a> to this number {date}.',
            alreadyEmailedLink: '{userName} has already emailed a <a target="_blank" href="{link}">client</a> at this address {date}.',
            alreadySentEmail: '{0} already sent an email to the client {1}.',
            attachment: 'Files',
            bcc: 'BCC',
            category: 'Category',
            calling: '{0} is currently calling the client, please try again later.',
            cc: 'CC',
            civility: 'Civility',
            division: 'Division',
            forwardTo: 'Forward To',
            ignore: 'Ignore',
            ignoreTooltip: 'Hides this message and allows you to communicate with the client',
            nextAutomationJob: 'An automated email will be sent on {0}.',
            notify: 'Notifiy',
            notifyTooltip: 'This will assign the current lead to {0} and send a notification',
            myself: 'me',
            originalCommunication: 'Original email',
            originalCommunicationTooltip: 'Include original client email to your email',
            addLastEmailBody: 'Previous email',
            addLastEmailBodyTooltip: 'Include previous email to your email',
            relativeFields: 'Roles',
            selectedRecipient: '{count} selected recipient | {count} selected recipients',
            sender: 'Sender',
            senderBackup: 'Fallback sender',
            sendingEmail: '{0} is currently sending an email to the client, please try again later.',
            sendingSms: '{0} is currently communicating via SMS with the client, please try again later.',
            insertVehicle: 'Insert Vehicle',
            viewDetails: 'View Details',
            signatureWillBeUpdatedOnSend: 'The signature will be updated according to the selected sender.',
            specificUser: 'Specific user',
            leadBelongToSomeoneElse: 'This lead is assigned to {0}. Are you sure you want to contact the customer?',
            subject: 'Subject',
            template: 'Template',
            to: 'Recipient',
            wantedVehicleLink: 'Vehicle lien',
            wantedVehicleDrsLinkDealer: 'DRS link dealer',
            wantedVehicleDrsLinkClient: 'DRS link client',
            leadRelatedUser: {
                user: 'Associate',
                bdcUser: 'BDC Agent',
                serviceAdvisorUser: 'Service advisor',
                serviceAgentUser: 'Service agent',
                commercial: 'F&I',
                custom: 'Custom',
            },
            imapIsDisabledAlert: 'Your IMAP in the CRM is inactive. New emails won’t appear in CRM, and CRM-sent emails will come from an internal CRM email. Please reactivate your IMAP for seamless email integration:',
            imapIsDisabledDocumentationLink: 'See documentation',
            dismiss: 'Dismiss',
        },
        massActions: {
            caslConsent: 'Manual consent',
            title: 'Bulk actions',
            massEdit: 'Update',
            massDelete: 'Delete',
            massMailing: 'Mailing',
            campaign: 'Create a campaign',
            selectField: 'Select a field to update',
            field: 'Field',
            form: 'Form',
            numberOfLeadsOverRange: 'You must select 5000 leads or less to create a campaign',
            numberOfLeadsUnderRange: 'You must select multiple leads to create a campaign',
            newValue: 'New value',
            division: 'Division',
            advisor: 'Associate',
            bdcAgent: 'BDC agent',
            commercial: 'F&I',
            optOut: 'Opt-out',
            event: 'Send to event',
            serviceAdvisor: 'Service advisor',
            serviceAgent: 'Service agent',
            status: 'Status',
            result: 'Result',
            civility: 'Civility',
            code: 'Code',
            language: 'Language',
            location: 'Location',
            source: 'Source',
            noNotification: 'No notification will be sent',
            unselectAllLeads: 'Clear selection',
            wantedVehicleModel: 'Vehicle model - W',
            wantedVehicleMake: 'Vehicle make - W',
            wantedVehicleTrim: 'Vehicle trim - W',
            wantedVehicleFrequency: 'Vehicle frequency - W',
            wantedVehicleModality: 'Vehicle modality - W',
            wantedVehicleResidual: 'Vehicle residual - W',
            wantedVehicleTerm: 'Vehicle term - W',
            wantedVehicleRate: 'Vehicle rate - W',
            wantedVehicleStockState: 'Vehicle stock state - W',
            wantedVehicleTransmission: 'Vehicle transmission - W',
            wantedVehicleFuel: 'Vehicle fuel - W',
            wantedVehiclePayment: 'Payment - W',
            wantedVehicleTaxedPayment: 'Taxed payment - W',
            exchangeVehicleInServiceDate: 'In service Date - C',
            exchangeVehicleFrequency: 'Vehicle frequency - C',
            exchangeVehicleModality: 'Vehicle modality - C',
            exchangeVehicleResidual: 'Vehicle residual - C',
            exchangeVehicleTerm: 'Vehicle term - C',
            exchangeVehicleRate: 'Vehicle rate - C',
            exchangeVehicleTransmission: 'Vehicle transmission - C',
            exchangeVehicleFuel: 'Vehicle fuel - C',
            exchangeVehicleIntention: 'Vehicle intention - C',
            exchangeVehicleCondition: 'Vehicle condition - C',
            exchangeVehicleValue: 'Vehicle value - C',
            exchangeVehicleStockSate: 'Vehicle stock state - C',
            exchangeVehiclePayment: 'Payment - C',
            exchangeVehicleTaxedPayment: 'Taxed payment - C',
            warningDelete: 'Deletion in progress...',
            warningAssociate: 'Association in progress...',
            warningUpdate: 'Update in progress...',
            warningMerge: 'Merger in progress...',
            group: {
                lead: 'Lead',
                wantedVehicle: 'Wanted Vehicle',
                exchangeVehicle: 'Current Vehicle',
                custom: 'Custom field',
            },
        },
        options: {
            massMailing: 'Mass mailing',
            bcc: 'BCC',
            cc: 'CC',
            assigned: 'Assigned',
            defaultCc: 'Default CC emails',
            defaultBcc: 'Default BCC emails',
            emailCc: 'CC emails',
            emailBcc: 'BCC emails',
            defaultFowarding: 'Recipient',
        },
        leadFilter: {
            advisor: 'Associate',
            secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
            user: 'Associate',
            leadForm: 'Form',
            serviceAdvisor: 'Service Advisor',
            wantedVehicleModel: 'Vehicle model - W',
            wantedVehicleMake: 'Vehicle make - W',
            exchangeVehicleMake: 'Vehicle make - C',
            exchangeVehicleModel: 'Vehicle model - C',
            source: 'Source',
            provider: 'Provider',
            bdcUser: 'BDC',
            commercial: 'Financial director',
            nbClient: 'Leads',
            takeOver: 'Turn over',
            serviceAgent: 'Service agent',
            account: 'Account',
            noAdvisor: 'No assigned associate',
            noCampaign: 'No campaign',
            noReferrer: 'No referrer',
            noLeadForm: 'No Form',
            noWantedVehicleMake: 'No Vehicle make - W',
            noWantedVehicleModel: 'No Vehicle model - W',
            noExchangeVehicleMake: 'No Vehicle make - C',
            noExchangeVehicleModel: 'No Vehicle model - C',
            noEndContractDate: 'No End of Contract Date',
            noDivision: 'No Division',
            noSecondaryAdvisor: 'No @:lexicon.secondaryAdvisor.singular',
            noServiceAdvisor: 'No Service Advisor',
            noSource: 'No Source',
            noApprovedSource: 'No Approved Source',
            noProvider: 'No Provider',
            noBdcUser: 'No assigned BDC',
            noCommercial: 'No assigned F&I',
            noTakeOverDirector: 'No assigned T.O. Dir.',
            noServiceAgent: 'No assigned Service Agent',
            endContractDate: 'End Contract',
            dayOfWeek: 'Day of the week',
            month: 'Month',
            year: 'Year',
            division: 'Division',
            weekOfYear: 'Week of year',
            campaign: 'Campaign',
            referrer: 'Referrer',
        },
        service: {
            cards: {
                appointment: 'Appointment | Appointments',
                average: 'of average',
                clients: 'Client | Clients',
                clientsPhoneUp: 'Phone-up Client | Phone-up Clients',
                clientsPrebooking: 'Pre-booking Client | Pre-booking Clients',
                clientsWeb: 'Web Client | Web Clients',
                confirmation: 'Confirmed | Confirmed',
                maintenance: 'Maintenance @:lexicon.sold.singular | Maintenance @:lexicon.sold.plural',
                ofConfirmation: 'of confirmations',
                overSale: 'Maintenance + @.capitalize:lexicon.sale.singular',
                overSaleCardBack: 'Clients with additional services @:lexicon.sold.plural',
                presentedCard: 'Visit | Visits',
                total: 'Total',
                visitWithAppointment: 'With appt.',
                visitWithoutAppointment: 'Without appt.',
            },

            columns: {
                advisorAgent: 'Service Advisor / Agent',
                date: 'Date',
                creationDate: 'Creation',
                lastPresentedDate: 'Last visit date',
                odometerLastVisit: 'Last visit KM',
                nextPresentedDate: 'Next revival',
                segment: 'Segment',
                appt: 'Appt.',
                confirmation: 'Confirmed',
                presented: 'Visit',
                maintenance: 'Maintenance',
                overSale: 'Service +',
                totalPrice: 'Total $',
                totalMinutes: 'Total hr',
                listening: 'Audio',
                comment: 'Notes',
                code: 'Code',
                storage: 'Storage',
                averageSpending: 'Visit $',
                serviceIntervalKm: 'Interval KM',
                serviceMonthlyKm: 'Monthly KM',
            },

            categories: {
                alignment: 'Align.',
                body: 'Body',
                brakes: 'Brakes',
                maintenance: 'Maintenance',
                motor: 'Motor',
                others: 'Others',
                parts: 'Parts',
                tires: 'Tires',
                transmission: 'Trans.',
                wash: 'Wash',
                problem: 'Problem',
                cleaning: 'Cleaning',
                all: 'All',
            },

            tooltip: {
                product_checkbox_disabled: 'You must use the client card to update the @:lexicon.sold.singular services.',
            },

            actions: {
                enableResultStats: 'Click to see stats depending on dates of creation and/or results (appt., visit)',
            },

            client: 'Customer number',
            appt: 'Appt.',
            // presented: 'Visit',
            folowing: 'Folowing',
            agent: 'Agent',
            appt_date: 'Appt. date',
            call_date: 'Call date',
            // total: 'Total',
            call_by_agent: 'Calls by agent',
            call_by_account: 'Calls by account',
            calls: 'Calls',
            transfer_time: 'Transfer time',
            transfer_time_agent: 'Average transfer time between the receptionist and the agent',
            transfer_time_card: 'Average transfer time between the receptionist and the associate',
            appt_sub: 'of confirmed appointment',
            lead_appt_card: 'Number of appointments confirmed on the number of leads',
            call_answered: 'Calls answered',
            sertiImportedTooltip: 'Value imported by Serti',

            hideNoLeadAccount: 'Hide accounts with no leads',
            hideNoLeadAdvisor: 'Hide service advisor\'s with no leads',
            showNoLeadAccount: 'Show accounts with no leads',
            showNoLeadAdvisor: 'Show service advisor\'s with no leads',
        },
        emailLead: {
            cantSendEmail: 'You do not have access to email this lead. | You do not have the access to email these leads.',
            cantSendSameEmail: 'You already sent an identical email to this lead in the last 24 hours.',
            invalidEmail: '{lead} does not have a valid email address. | Leads do not have valid email addresses.',
            unsubscribeRequired: 'You need the unsubscribe link for mass mailing.',
        },
        importation: {
            ruleAlert: {
                title: 'Warning',
                text: 'Are you sure you want to merge leads? This action is irreversible',
                yes: 'Yes',
                no: 'No',
            },
        },
        export: {
            addPhone: 'Add phone',
            add: 'Add',
            addName: 'Add a name',
            lucyPushNoPhoneText: 'The customer must have a phone number or an email address',
            lucyPushNoPhoneTitle: 'Phone number or email address required',
            lucyPushNoNameText: 'The customer must have a name',
            lucyPushNoNameTitle: 'A name is required',
            honda: {
                needModelTrimAlert: 'To send the lead to Honda Canada, you have to choose a valid model.',
                needTrimAlert: 'To send the lead to Honda Canada, you have to choose a valid trim.',
            },
        },
        progress_state: {
            approved: 'Approved',
            cash: 'Cash down',
            cash_deal: 'Cash deal',
            endorser: 'Endorser',
            proof: 'Proof',
            vehicle_selection: 'Vehicle to select',
            refused: 'Refused',
            sent: 'Sent',
        },
        filters: {
            all: 'All',
            apply: 'Apply',
            clearAllFilters: 'Clear all filters',
            filters: 'Filters',
            none: 'None',
            reset: 'Reset',
            showAll: 'Show all',

            vehicle_frequency: {
                one_payment: 'One payment',
                weekly: 'Weekly',
                bi_monthly: 'Bimonthly',
                two_weeks: 'Two weeks',
                monthly: 'Monthly',
            },
            appointment: {
                phoneAppointment: 'Phone Appt.',
                phoneAppointmentCompleted: 'Completed',
                canceled: 'Canceled',
                confirmed: 'Confirmed',
                noDate: 'Appt. without date',
                noShow: 'No show',
                notConfirmed: 'Not confirmed',
                noTime: 'No time',
                toReach: 'Associate must reach',
                uncertain: 'Uncertain client',
                yesWithoutStatus: 'Yes (without status)',
                notCompleted: 'Not completed',
                completed: 'Completed',
                tomorrow: 'Appt. tomorrow',
            },
            lead_type: {
                email: 'Web Lead',
                phone: 'Phone-up',
                walk_in: '@.capitalize:lexicon.walkIn.singular',
                loyalty: 'Loyalty',
                renewal: '@.capitalize:lexicon.portfolio.singular',
                event: 'Event',
                pre_booking: 'Pre-booking',
                sms: 'SMS',
                dms: 'DMS',
                web_order: 'Web Order',
            },
            presented: {
                presented: '@.capitalize:lexicon.presented.singular',
                presentedOther: '@.capitalize:lexicon.presentedOther.singular',
            },
            roadTest: {
                roadTest: 'Test drive',
                homeRoadTest: 'Home Test drive',
            },
            media: {
                audio: 'Audio',
                video: 'Video',
                noAudioNote: 'No audio note',
                noFileAttached: 'No File attached',
                audioNote: 'Audio note',
                fileAttached: 'File attached',
            },
        },
        sorting: {
            title: 'Sorting',

            newestFirst: 'Newest first',
            oldestFirst: 'Oldest first',
        },
        duplicates: {
            action: 'Action',
            associatedLeads: '{0} associated lead | {0} associated leads',
            associatedSuccessfully: 'Leads associated successfully.',
            boolean: {
                false: 'No',
                null: 'No',
                true: 'Yes',
            },
            clientName: 'Client name',
            delivered: '@.capitalize:lexicon.delivered.singular',
            division: 'Division',
            email: 'Email',
            endDate: 'End date',
            groupes: 'Groups',
            leadDeletedSuccessfully: 'Leads deleted successfully.',
            leadType: 'Client type',
            matchBy: 'Match by',
            mergedSuccessfully: 'The leads have been merged successfully.',
            name: 'Name',
            noResult: 'No results were found',
            offerNumber: 'Offer #',
            orderNumber: 'Order #',
            phone: 'Phone',
            results: 'Results',
            search: 'Search',
            source: 'Source',
            startDate: 'Start date',
            dateType: 'Date Type',
            dateTypeTooltip: 'Select all the dates to be considered in the search',
        },
        confidentialityAgreement: {
            termsAndConditionsConfirmation: 'I have read and agree to the Privacy Policy and the Terms of Use',
            iAgree: 'I agree',
        },
    },
};
