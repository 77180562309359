import { toCurrency } from '@/utils/numbers.js';
import { useI18n } from '@/hooks/useI18n.ts';
import { InventoryUnit } from '../entities/types/InventoryUnit.ts';

export const useVehicleBrochure = () => {
    const { t, n } = useI18n();

    /**
     * Get the vehicle brochure.
     *
     * Although the html code could largely be optimized in many ways,
     * it must remain as written to ensure maximum compatibility and preserve style consistency across different email clients
     * that have html and css limitations (especially for older versions of Microsoft Outlook for Windows).
     * For that reason, we cannot remove cellspacing and cellpadding obsolete attributes, etc.
     */
    const getVehicleBrochure = (vehicle: InventoryUnit, lang: string): string => {
        return `
            <div>
                <table
                    cellspacing="0"
                    cellpadding="0"
                    style="
                        width: 332px;
                        padding: 0;
                        font-family: 'Source Sans Pro', sans-serif;
                        border: 1px solid #D9D9D9;
                        border-spacing: 0;
                        border-radius: 6px;
                        box-shadow: 0 0 5px 2px #D9D9D9;
                    "
                >
                    <thead>
                        <tr>
                            <td
                                colspan="2"
                                style="
                                    padding: 25px 25px 3px 25px;
                                    font-size: 14px;
                                "
                            >
                                ${getYearAndMake(vehicle)}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="2"
                                style="
                                    padding: 3px 25px 3px 25px;
                                    font-size: 16px;
                                "
                            >
                                ${getModel(vehicle)}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="2"
                                style="
                                    padding: 3px 25px 15px 25px;
                                    font-size: 14px;
                                    color: #525252;
                                "
                            >
                                ${getPrice(vehicle, lang)}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="2"
                                contenteditable="false"
                                style="
                                    padding: 0 25px 0 25px;
                                "
                            >
                                <table
                                    cellspacing="0"
                                    cellpadding="0"
                                    style="
                                        width: 100%;
                                        height: 3px;
                                        padding: 0;
                                        border-spacing: 0;
                                    "
                                >
                                    <tr>
                                        <td
                                            contenteditable="false"
                                            style="
                                                height: 3px;
                                                padding: 0;
                                                background-color: #E1E1E1;
                                            "
                                        >
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                contenteditable="false"
                                style="
                                    width: 50%;
                                    padding: 20px 10px 25px 25px;
                                    vertical-align: middle;
                                    text-align: center;
                                "
                            >
                                <img
                                    src="${getImage(vehicle)}"
                                    alt="vehicle_image"
                                    width="130"
                                    style="
                                        vertical-align: middle;
                                        border-radius: 6px;
                                    "
                                />
                            </td>
                            <td
                                contenteditable="false"
                                style="
                                    width: 50%;
                                    padding: 20px 25px 25px 10px;
                                    vertical-align: middle;
                                    font-size: 14px;
                                "
                            >
                                <table
                                    cellspacing="0"
                                    cellpadding="0"
                                    style="
                                        width: 100%;
                                        padding: 0;
                                        font-family: 'Source Sans Pro', sans-serif;
                                        border-spacing: 0;
                                    "
                                >
                                    <tr>
                                        <td
                                            contenteditable="true"
                                            style="
                                                padding: 3px 0;
                                            "
                                        >
                                            ${getExteriorAndInteriorColor(vehicle)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            contenteditable="true"
                                            style="
                                                padding: 3px 0;
                                            "
                                        >
                                            ${getDrivetrain(vehicle)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            contenteditable="true"
                                            style="
                                                padding: 3px 0;
                                            "
                                        >
                                            ${getTransmission(vehicle)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            contenteditable="true"
                                            style="
                                                padding: 3px 0;
                                            "
                                        >
                                            ${getOdometer(vehicle, lang)}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    ${getVdpUrlButton(vehicle, lang)}
                </table>
            </div>
            <p><br></p>
        `;
    };

    const getYearAndMake = (vehicle: InventoryUnit): string => {
        return `${vehicle.year ?? '---'} ${vehicle.make ?? '---'}`;
    };

    const getModel = (vehicle: InventoryUnit): string => {
        return vehicle.model ?? '---';
    };

    const getPrice = (vehicle: InventoryUnit, lang: string): string => {
        return vehicle.price ? `${toCurrency(vehicle.price, true, false, '', 'CA', lang)}` : '---';
    };

    const getImage = (vehicle: InventoryUnit): string => {
        return vehicle.inventory_unit_image?.url ??
            vehicle.vehicle_image?.image_url_front ??
            'https://res.cloudinary.com/activix/image/upload/v1737653327/dev/image-missing-icon.png';
    };

    const getExteriorAndInteriorColor = (vehicle: InventoryUnit): string => {
        const exterior = vehicle.color_exterior === 'none' ? '---' : vehicle.color_exterior;
        const interior = vehicle.color_interior === 'none' ? '---' : vehicle.color_interior;
        return `${exterior} + ${interior}`;
    };

    const getDrivetrain = (vehicle: InventoryUnit): string => {
        return vehicle.drivetrain === 'none' ? '---' : vehicle.drivetrain;
    };

    const getTransmission = (vehicle: InventoryUnit): string => {
        return vehicle.transmission === 'none' ? '---' : vehicle.transmission;
    };

    const getOdometer = (vehicle: InventoryUnit, lang: string): string => {
        const unit = vehicle.odometer_unit ?? 'km';

        if (!vehicle.mileage) {
            return `--- ${unit}`;
        }

        return n(vehicle.mileage, unit, lang.toLowerCase());
    };

    /**
     * Get the vehicle brochure footer with VDP button if the url is available.
     *
     * The vml code section (<!--[if mso]>) must remain to ensure compatibility with older versions of Microsoft Outlook for Windows.
     */
    const getVdpUrlButton = (vehicle: InventoryUnit, lang: string): string => {
        let url;
        if (lang.toLowerCase() === 'fr') {
            url = vehicle.vdp_url_fr ?? vehicle.vdp_url_en;
        } else {
            url = vehicle.vdp_url_en ?? vehicle.vdp_url_fr;
        }

        if (url) {
            return `
                <tfoot>
                    <tr>
                        <td
                            colspan="2"
                            contenteditable="false"
                            style="
                                padding: 0 25px 25px 25px;
                                vertical-align: middle;
                                text-align: center;
                            "
                        >
                            <!--[if mso]>
                            <v:roundrect
                                xmlns:v="urn:schemas-microsoft-com:vml"
                                xmlns:w="urn:schemas-microsoft-com:office:word"
                                href="${url}"
                                style="
                                    height: 36px;
                                    v-text-anchor: middle;
                                    width: 280px;
                                "
                                arcsize="9%"
                                stroke="f"
                                fillcolor="#3E8DBC"
                            >
                                <w:anchorlock/>
                                <center
                                    style="
                                        font-family: 'Source Sans Pro', sans-serif;
                                    "
                                >
                            <![endif]-->
                                <a
                                    href="${url}"
                                    target="_blank"
                                    style="
                                        display: inline-block;
                                        width: 100%;
                                        padding: 0;
                                        line-height: 36px;
                                        font-size: 14px;
                                        color:#FFFFFF;
                                        background-color: #3E8DBC;
                                        text-decoration: none;
                                        border-radius: 3px;
                                        box-shadow: 0 0 5px 2px #D9D9D9;
                                    "
                                >
                                    ${getButtonText(lang)}
                                </a>
                            <!--[if mso]>
                                </center>
                            </v:roundrect>
                            <![endif]-->
                        </td>
                    </tr>
                </tfoot>
            `;
        }
        return '';
    };

    const getButtonText = (lang: string): string => {
        return t('responseLeadXpress.viewDetails', [], lang.toLowerCase());
    };

    const minify = (content: string): string => {
        return content.replace(/\s{2,}/g, ' ').replace(/\n/g, ' ').trim();
    };

    return {
        getVehicleBrochure,
        minify,
    };
};
